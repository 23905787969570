import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useInputStore from '../../store/common/useInputStore';
import usePopupStore from '../../store/common/usePopupStore';
import useAuthStore from '../../store/common/useAuthStore';
import { accountLogin } from '../../services/GQ_apis/auth';
import { useLanguage } from '../../services/languageContext'; // 언어 컨텍스트 사용

const LoginMain02Section = () => {
  const navigate = useNavigate();
  const { login } = useAuthStore();
  const { getTranslatedNameById } = useLanguage(); // 번역 함수 사용

  const { input, actions } = useInputStore((state) => ({
    input: state.input,
    actions: state.actions,
  }));

  // SnackBar 스낵바 팝업 사용 함수 openSnackBarPopup
  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup, // 스낵바 오픈 함수
  }));

  const handleLogin = () => {
    accountLogin(input.email, input.password)
      .then((response) => {
        console.log('응답 데이터:', response); // 응답 로그 추가
        if (response.data && response.data.tokenCreate && response.data.tokenCreate.token) {
          login(response.data);
          openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NDk4"), '', ''); // 정상적으로 로그인되었습니다.
          navigate('/');
        } else {
          openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NDk5"), '', ''); // 아이디와 비밀번호를 확인해주세요.
        }
      })
      .catch((error) => {
        console.log('로그인 오류:', error); // 에러 로그 추가
        openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NTAw"), '', ''); // 로그인 도중 오류가 발생했습니다.
        navigate('/login/main2');
      });
  };

  const redirectUri = (process.env.REACT_APP_SNS_REDIRECT_URI || 'http://localhost:3000').replace(/\/$/, '');

  const KAKAO_CLIENT_ID = process.env.REACT_APP_SNS_KAKAO_CLIENT_ID || '8235f3462d95f53f7513be2fedda286d';
  const GOOGLE_CLIENT_ID = process.env.REACT_APP_SNS_GOOGLE_CLIENT_ID || '489777983005-1stqspjbv59bqcarppoc7o57cbkk2lfm.apps.googleusercontent.com';


  if (!KAKAO_CLIENT_ID) {
    console.error('Kakao Client ID가 설정되지 않았습니다.');
  }

  if (!GOOGLE_CLIENT_ID) {
    console.error('Google Client ID가 설정되지 않았습니다.');
  }

  if (!redirectUri) {
    console.error('Redirect URI가 설정되지 않았습니다.');
  }

  // 카카오 및 구글 인증 URL 생성
  const KAKAO_AUTH_URL = KAKAO_CLIENT_ID && redirectUri
    ? `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${redirectUri}/auth/kakao/callback&response_type=code`
    : null;

  const GOOGLE_AUTH_URL = GOOGLE_CLIENT_ID && redirectUri
    ? `https://accounts.google.com/o/oauth2/auth?client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${redirectUri}/auth/google/callback&response_type=code&scope=email%20profile`
    : null;

  // 카카오 인증 페이지로 리디렉션
  const handleKakaoLogin = () => {
    if (KAKAO_AUTH_URL) {
      window.location.href = KAKAO_AUTH_URL; 
    } else {
      openSnackBarPopup('Kakao 로그인 URL이 설정되지 않았습니다.', '', '');
    }
  };

  // 구글 인증 페이지로 리디렉션
  const handleGoogleLogin = () => {
    if (GOOGLE_AUTH_URL) {
      window.location.href = GOOGLE_AUTH_URL;
    } else {
      openSnackBarPopup('Google 로그인 URL이 설정되지 않았습니다.', '', '');
    }
  };

  // 마운트 시 resetInput 초기화
  useEffect(() => {
    actions.resetInput();
  }, [actions.resetInput]);

  return (
    <section className="section-login-main02">
      <div className="inner">
        <span className="icon-logo-yellow"></span>

        <div className="input-box">
          <div className="input">
            <label htmlFor="email" className="hide">{getTranslatedNameById("TWVudUl0ZW06MzA5")} </label>
            <input
              id="email"
              type="email"
              placeholder={getTranslatedNameById("TWVudUl0ZW06NDg2")} 
              onInput={(e) => actions.setEmail(e.target.value)}
            />
          </div>
          <div className="input">
            <label htmlFor="password" className="hide">{getTranslatedNameById("TWVudUl0ZW06NDg3")}</label>
            <input
              id="password"
              type="password"
              placeholder={getTranslatedNameById("TWVudUl0ZW06NDg4")} 
              onInput={(e) => {
                actions.setPassword(e.target.value);
                actions.validatePasswords();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleLogin();
                }
              }}
            />
          </div>
        </div>
        <button
          type="button"
          className="btn purple big mt14"
          onClick={handleLogin}
        >
          {getTranslatedNameById("TWVudUl0ZW06Mjc1")} {/* 로그인 */}
        </button>
        <ul className="a-list">
          <li>
            <Link to="/login/findid" title="">
              {getTranslatedNameById("TWVudUl0ZW06NTAx")} {/* 아이디 찾기 */}
            </Link>
          </li>
          <li>
            <Link to="/login/findpw" title="">
              {getTranslatedNameById("TWVudUl0ZW06NTAy")} {/* 비밀번호 찾기 */}
            </Link>
          </li>
          <li>
            <Link to="/register/1" title="">
            {getTranslatedNameById("TWVudUl0ZW06NDc2")} {/* 회원가입 */}
            </Link>
          </li>
        </ul>
        <div className="sns-wrap">
          <p className="title">
            {getTranslatedNameById("TWVudUl0ZW06NTAz")} {/* SNS 계정으로 로그인 */}
          </p>
          <ul className="sns-list">
            <li>
              <a
                className="icon-kakao"
                href="#none"
                title="카카오 로그인"
                onClick={handleKakaoLogin}
              ></a>
            </li>
            <li>
              <a
                className="icon-google"
                href="#none"
                title="구글 로그인"
                onClick={handleGoogleLogin}
              ></a>
            </li>
          </ul>
        </div>
        <div className="under-line">
          <Link className="under-line" to="/" title="">
            {getTranslatedNameById("TWVudUl0ZW06NDgz")} {/* 혜택 받지 않고 둘러보기 */}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default LoginMain02Section;

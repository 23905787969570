import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import BrandTab from '../../components/Tabs/BrandTab';
import H3Title from '../../layouts/title/H3Title';
import GridSection from '../../sections/grid/GridSection';
import BannerDSection from '../../sections/banner/BannerDSection';
import BannerFSection from '../../sections/banner/BannerFSection';
import SmallTab from '../../components/Tabs/SmallTab'; 
import { getBrandList } from '../../services/REST_apis/vendor';
import { getCollection } from '../../services/GQ_apis/collection';
import { useLanguage } from '../../services/languageContext';

const slugs = [
  'brand1',
  'brand2',
  'brand3',
  'brand4',
  'brand5',
  'brand6'
];

const BrandMainSection = () => {
  const [activeSmallTab, setActiveSmallTab] = useState({});
  const [vendorList, setVendorList] = useState({});
  const [collection, setCollection] = useState([]);
  const [vidMap, setVidMap] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { getTranslatedNameById } = useLanguage();

  // 채널 확인
  const channel = window.WSgetChannel();

  const handleSmallTabClick = useCallback((key, collectionId) => {
    setActiveSmallTab(prevState => ({
      ...prevState,
      [collectionId]: key
    }));

    const selectedVendor = vendorList[collectionId]?.[key];
    if (selectedVendor) {
      setVidMap(prevState => ({
        ...prevState,
        [collectionId]: selectedVendor.id
      }));
    }
  }, [vendorList]);

  useEffect(() => {
    const fetchVendorList = async () => {
      try {
        const fetchedVendorList = await getBrandList();
        setVendorList(fetchedVendorList);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchCollections = async () => {
      try {
        const res = await getCollection(slugs);
        const data = res.data.collections.edges;

        const formattedData = data.map(el => {
          let title = getTranslatedNameById('TWVudUl0ZW06Mjkx');
          if (el.node.description) {
            const parsedDescription = JSON.parse(el.node.description);
            title = parsedDescription.blocks
              .map(block => block.data.text)
              .join('<br>');
          }

          return {
            title: title,
            id: el.node.id,
            name: el.node.name,
            slug: el.node.slug,
            privateMetadata: el.node.privateMetadata,
            metadata: el.node.metadata
          };
        });

        setCollection(formattedData);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching collections:', error);
        setIsLoading(false); 
      }
    };

    fetchVendorList();
    fetchCollections();
  }, []);

  const formatSmallTab = useCallback((list) => (
    Array.isArray(list) ? list.map((col, index) => ({
      key: index,
      vid: col.id,
      label: (channel === 'KO' || !col.store_name_en) ? col.store_name_ko : col.store_name_en,
      description: col.introduction_ko,
      picture: col.vendor_thumbnail
    })) : []
  ), [channel]);
  
  if (isLoading) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  return (
    <section className="section-brand-main">
      <div className="banner-wrap">
        <BannerFSection id='brand_b1'/>
      </div>
      
      {collection.slice(0, 3).map((col, index) => (
        <React.Fragment key={index}>
          <BrandTab title={col.title} tabs={vendorList[col.name]} />
          
          {index === 0 && (
            <div className="white-wrap pt20">
              {collection[0] && (
                <>
                  <H3Title title={collection[0].title}/>

                  <SmallTab
                    tabs={formatSmallTab(vendorList[collection[0].name])}
                    activeTab={activeSmallTab[collection[0].name]}  
                    onTabClick={(key) => handleSmallTabClick(key, collection[0].name)}
                  />

                  <div className="banner-wrap mb8 mt20">
                    <BannerDSection id='brand_b3'/>
                  </div>

                  <GridSection 
                    type="middle" 
                    option={false}
                    collection={collection[0].id}
                    vid={vidMap[collection[0].name] || ""}
                  />

                  { (activeSmallTab[collection[0].name] != null) &&
                    <button 
                      type="button" 
                      className="btn big round shortcuts mt40"
                      onClick={() => navigate(`/brand/detail/${vidMap[collection[0].name]}`)}
                    >
                      {getTranslatedNameById('TWVudUl0ZW06Mjcz')}
                    </button>
                  }
                </>
              )}
            </div>
          )}
        </React.Fragment>
      ))}

      <div className="white-wrap pt20">
        {collection[3] && (
          <>
            <H3Title title={collection[3].title}/>

            <SmallTab
              tabs={formatSmallTab(vendorList[collection[3].name])}
              activeTab={activeSmallTab[collection[3].name]} 
              onTabClick={(key) => handleSmallTabClick(key, collection[3].name)}
            />

            <div className="banner-wrap mb8 mt20">
              <BannerDSection id='brand_b4'/>
            </div>

            <GridSection 
              type="middle" 
              option={false}
              collection={collection[3].id}
              vid={vidMap[collection[3].name] || ""}
            />
            { activeSmallTab[collection[3].name] != null &&
              <button 
                type="button" 
                className="btn big round shortcuts mt40"
                onClick={() => navigate(`/brand/detail/${vidMap[collection[3].name]}`)}
              >
                {getTranslatedNameById('TWVudUl0ZW06Mjkw')} {getTranslatedNameById('TWVudUl0ZW06Mjg4')}
              </button>
            }
          </>
        )}
      </div>

      {collection.slice(4).map((col, index) => (
        <div className="white-wrap pt20" key={index + 3}>
          <H3Title title={col.title} />

          <GridSection 
            type="big" 
            option={false}
            collection={col.id} 
            vid={vidMap[col.name] || ""}
          />

          <button 
            type="button" 
            className="btn big round more mt40"
            onClick={() => navigate(`/product-list?id=${col.id}&title=${col.title}`)}
          >
            {getTranslatedNameById('TWVudUl0ZW06Mjcz')}
          </button>
        </div>
      ))}
    </section>
  );
};

export default BrandMainSection;

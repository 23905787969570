import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useInputStore from '../../store/common/useInputStore';
import usePopupStore from './../../store/common/usePopupStore';
import { createChild, getMyChildren } from '../../services/GQ_apis/kid';
import { useLanguage } from '../../services/languageContext'; // 언어 컨텍스트 사용

const KidRegister04Section = () => {
  const navigate = useNavigate();
  const { getTranslatedNameById, language } = useLanguage();
  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
  }));
  const [kids, setKids] = useState([]);

  useEffect(() => {
    getMyChildren().then((res) => {
      setKids(res.data.myChildren);
    });
  }, []);

  const {
    input,
    actions,
  } = useInputStore(state => ({
    input: state.input,
    kid: state.kid,
    actions: state.actions,
  }));

  // 번역된 아이템 리스트
  const itemList = [
    getTranslatedNameById("TWVudUl0ZW06Mzk3"), // 맨투맨
    getTranslatedNameById("TWVudUl0ZW06Mzk4"), // 셔츠
    getTranslatedNameById("TWVudUl0ZW06Mzk5"), // 자켓
    getTranslatedNameById("TWVudUl0ZW06NDAw"), // 청바지
    getTranslatedNameById("TWVudUl0ZW06NDAx"), // 면바지
    getTranslatedNameById("TWVudUl0ZW06NDAy"), // 점퍼
    getTranslatedNameById("TWVudUl0ZW06NDAz"), // 원피스
    getTranslatedNameById("TWVudUl0ZW06NDA0"), // 치마
    getTranslatedNameById("TWVudUl0ZW06NDA1")  // 상하복
  ];

  const [checkedStates, setCheckedStates] = useState(new Array(itemList.length).fill(false));
  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheckboxChange = (position) => {
    if (selectedItems.length >= 3 && !checkedStates[position]) return;

    const updatedCheckedStates = checkedStates.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedStates(updatedCheckedStates);

    if (!checkedStates[position]) {
      setSelectedItems([...selectedItems, itemList[position]]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item !== itemList[position]));
    }
  };

  const handleSubmit = async () => {
    console.log(selectedItems);

    let main = false;
    if (kids.length === 0) main = true;

    try {
      const updatedKidItemList = JSON.stringify(selectedItems);

      const response = await createChild(
        input.kidNickName, // name
        new Date(input.kidBirthDay).toISOString(), // birthDate
        parseFloat(input.kidHeight), // height
        parseFloat(input.kidWeight), // weight
        parseFloat(input.kidFootSize), // footSize
        parseFloat(input.kidHeadSize), // headSize
        input.kidGender, // gender
        main, // main
        input.kidProfileImg || '', // thumbnail
        input.kidBrandList, // brands
        input.kidColorList, // colors
        updatedKidItemList // items
      );

      const accountChild = response.data.accountChildCreate;
      if (accountChild.accountErrors.length === 0) {
        openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NDA2"), '', 'success'); // 아이 프로필 등록이 완료되었습니다.
        navigate('/');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <section className="section-register">
        <div className="inner">

          {/* 타이틀 영역 [START] */}
          <div className="h3-title-wrap m0">
            <h3 className="title">
              {getTranslatedNameById("TWVudUl0ZW06Mzkz")} {/* 선호 아이템 */}
            </h3>
            <p className="text">
              {language === 'KO' ? (
                <>
                  평소 선호하는 아이템을<br/>
                  1순위부터 3순위까지 선택해주세요.
                </>
              ) : (
                <>
                  {getTranslatedNameById("TWVudUl0ZW06Mzk0")} {/* 평소 선호하는 아이템을 1순위부터 3순위까지 선택해주세요. */}
                </>
              )}
            </p>
            <div className="pagination">
              <span className="item">
                1
              </span>
              <span className="item">
                2
              </span>
              <span className="item">
                3
              </span>
              <span className="item active">
                4
              </span>
            </div>
          </div>
          {/* 타이틀 영역 [END] */}

          {/* 아이템 체크 영역[START] */}
          <div className="check-wrap mt30">
            <ul className="check-list item">
              {itemList.map((item, index) => (
                <li key={index}>
                  <label
                    htmlFor={`check${index}`}
                    className={`${checkedStates[index] ? 'active' : ''} 
                    ${selectedItems.length >= 3 && !checkedStates[index] ? 'disabled' : ''}`}
                  >
                    {item}
                    <input
                      className="bg"
                      id={`check${index}`}
                      type="checkbox"
                      checked={checkedStates[index]}
                      onChange={() => handleCheckboxChange(index)}
                      disabled={selectedItems.length >= 3 && !checkedStates[index]}
                    />
                  </label>
                </li>
              ))}
            </ul>
          </div>
          {/* 아이템 체크 영역[END] */}
        </div>
      </section>
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button
              type="button"
              disabled={!(selectedItems.length >= 3)}
              className="btn purple big"
              onClick={handleSubmit}
            >
              {getTranslatedNameById("TWVudUl0ZW06Mzk1")} {/* 등록 완료 */}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default KidRegister04Section;

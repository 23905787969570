import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { addAddress, updateAddress, setDefaultAddress, fetchAddress } from '../../services/GQ_apis/address';
import DaumAddress from "../../components/api/DaumAddress";
import { useLanguage } from '../../services/languageContext'; 

const DeliveryDomesticSection = () => {

  const navigate = useNavigate();
  const { Page3depth } = useParams();
  const { getTranslatedNameById, language } = useLanguage(); 
  const [fromOrder, setFromOrder] = useState(false); // fromOrder 상태 관리

  useEffect(() => {

    if (!Page3depth) return;

    const urlParams = new URLSearchParams(window.location.search);
    const fromOrderParam = urlParams.get("from-order");

    if (fromOrderParam !== null && fromOrderParam !== "") {
      setFromOrder(true);
    }

    fetchAddress(Page3depth).then((res) => {

      var address = res.data.address;
      console.log('res.data.address', res.data.address);

      if (!address) return;

      setAddressName(address.metadata[0].value);
      setFirstName(address.firstName);
      setPhoneNumber(window.WSformatPhoneNumber(address.phone));
      setPostalCode(address.postalCode);
      setStreetAddress1(address.streetAddress1);
      setStreetAddress2(address.streetAddress2);
      setCustomsCode(address?.metadata[1]?.value);
      setMessage(address?.metadata[2]?.value);
      setCity(address.city);
      setCountryArea(address.countryArea);
    })

  },[])

  // 주소 데이터를 받아 상태를 업데이트하는 함수
  const handleSelectAddress = (data) => {

    console.log("Selected Address:", data);

    setPostalCode(data.zonecode); // 우편번호
    setCountryArea(data.sido); // 주소 - 구
    setCity(data.sigungu); // 도시
    setStreetAddress1(data.address); // 주소 1
    setIsDefault(data.isDefaultShippingAddress);

  };

  const [addressName, setAddressName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [phone, setPhoneNumber] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [countryArea, setCountryArea] = useState('');
  const [city, setCity] = useState('');
  const [customsCode, setCustomsCode] = useState('');
  const [message, setMessage] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [country] = useState('KR');
  const [streetAddress1, setStreetAddress1] = useState('');
  const [streetAddress2, setStreetAddress2] = useState('');

  const saveAddress = () => {
    let input = {
      "firstName": firstName,
      "phone": phone,
      "postalCode": postalCode,
      "countryArea": countryArea,
      "city": city,
      "country": country,
      "streetAddress1": streetAddress1,
      "streetAddress2": streetAddress2,
      "metadata": [
        { "key": "addressName", "value": addressName },
        { "key": "customsCode", "value": customsCode },
        { "key": "message", "value": message }
      ]
    };

    if (!Page3depth) {
      // 배송지 생성
      addAddress(input).then((res) => {
        if (res.data.accountAddressCreate.errors.length > 0) {
          alert(getTranslatedNameById("TWVudUl0ZW06MzQ3")); // "잘못된 주소입니다."
        } else {
          if (fromOrder) window.location.href = '/delivery/list?from-order';
          else window.location.href = '/delivery/list';

          setDefaultAddress(res.data.accountAddressCreate.address.id, 'SHIPPING');
          setDefaultAddress(res.data.accountAddressCreate.address.id, 'BILLING');
        }
      });
    } 
    else {
      // 배송지 업데이트
      updateAddress(Page3depth, input).then((res) => {
        window.location.href = '/delivery/list';
        if (!isDefault) return;

        setDefaultAddress(Page3depth, 'SHIPPING');
        setDefaultAddress(Page3depth, 'BILLING');
      });
    }

  }

  return (
    <>
      <section className="section-delivery-domestic">
        <div className="inner">
          <button 
            type="button" 
            className="btn purple delivery "
            onClick={()=>navigate('/delivery/overseas')}
          >
            <span className="icon-change"></span>
            {language === 'KO' ? '해외 배송' : 'International Shipping'}
          </button>
          <div className="input-box">
            <label htmlFor="inputAddressName" className="title starred">
              {getTranslatedNameById("TWVudUl0ZW06MzQw")} {/* "배송지명" */}
            </label>
            <div className="input">
              <input
                id="inputAddressName"
                className="space"
                type="text"
                placeholder={getTranslatedNameById("TWVudUl0ZW06MzQw")} 
                onInput={(e) => setAddressName(e.target.value)}
                value={addressName}
              />
            </div>
          </div>
          <div className="input-box">
            <label htmlFor="inputName" className="title starred">
              {getTranslatedNameById("TWVudUl0ZW06MzQx")} {/* "받는사람" */}
            </label>
            <div className="input">
              <input
                id="inputName"
                type="text"
                placeholder={getTranslatedNameById("TWVudUl0ZW06MzQx")} 
                onInput={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
            </div>
          </div>
          <div className="input-box">
            <label htmlFor="inputPhone" className="title starred">
              {getTranslatedNameById("TWVudUl0ZW06MzE3")} {/* "연락처" */}
            </label>
            <div className="input">
              <input
                id="inputPhone"
                type="text"
                placeholder={getTranslatedNameById("TWVudUl0ZW06MzE3")} 
                onInput={(e) => setPhoneNumber(e.target.value)}
                value={phone}
              />
            </div>
          </div>
          <div className="input-box">
            <label htmlFor="inputPostalCode" className="title starred">
              {getTranslatedNameById("TWVudUl0ZW06MzI1")} {/* "주소" */}
            </label>
            <div className="input">
              <input
                id="inputPostalCode"
                type="text"
                placeholder={getTranslatedNameById("TWVudUl0ZW06MzQ5")} 
                readOnly
                value={postalCode}
              />
              <DaumAddress onSelectAddress={handleSelectAddress} />
            </div>
            <div className="input">
              <input
                id="countryArea"
                type="text"
                placeholder={getTranslatedNameById("TWVudUl0ZW06MzI1")} 
                readOnly
                value={streetAddress1}
              />
            </div>
            <div className="input">
              <input
                id="city"
                className="space"
                type="text"
                placeholder={getTranslatedNameById("TWVudUl0ZW06MzUw")} 
                onInput={(e) => setStreetAddress2(e.target.value)}
                value={streetAddress2}
              />
            </div>
          </div>
          <div className="input-box space">
            <label htmlFor="message" className="title">
              {getTranslatedNameById("TWVudUl0ZW06MzQy")} {/* "배송 메시지" */}
            </label>
            <div className="input space">
              <input
                id="message"
                className="space"
                type="text"
                placeholder={getTranslatedNameById("TWVudUl0ZW06MzUx")}
                onInput={(e) => setMessage(e.target.value)}
                value={message}
              />
            </div>
          </div>
          <div className="input-box">
            <label htmlFor="customsCode" className="title starred">
              {getTranslatedNameById("TWVudUl0ZW06MzQz")} {/* "개인통관부호" */}
            </label>
            <div className="input">
              <input
                id="customsCode"
                type="number"
                placeholder={getTranslatedNameById("TWVudUl0ZW06MzQz")} 
                onInput={(e) => setCustomsCode(e.target.value)}
                value={customsCode}
              />
            </div>
            <p className="caption f-purple mt4 mb4">
              {getTranslatedNameById("TWVudUl0ZW06MzQ0")} {/* "개인통관부호가 없으신 분은..." */}
            </p>
            <a className="caption f-purple" href="https://unipass.customs.go.kr/csp/index.do" target="_blank">
              https://unipass.customs.go.kr/csp/index.do
            </a>
          </div>
          <div className="input-box">
            <div className="input check bg">
              <label htmlFor="check0">
                <input
                  id="check0"
                  type="checkbox"
                  onChange={(e) => setIsDefault(e.target.checked)}
                  checked={isDefault}
                />
                {getTranslatedNameById("TWVudUl0ZW06MzQ1")} {/* "해당 주소를 기본 배송지로 등록" */}
              </label>
            </div>
          </div>
        </div>
      </section>

      {/* 저장하기 버튼 */}
      <div className="bottom-bar">
        <div className="inner">
          <button type="button" className="btn big purple" onClick={() => saveAddress()}>
            {getTranslatedNameById("TWVudUl0ZW06MzQ2")} {/* "저장하기" */}
          </button>
        </div>
      </div>
    </>
  );
};

export default DeliveryDomesticSection;

import { useState, useEffect } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import TopBar from '../../layouts/top_bar/TitleBar';
import { completeCheckout } from '../../services/GQ_apis/checkout';
import { markOrderAsPaid, updateMetadata } from '../../services/GQ_apis/order';
import { useLanguage } from '../../services/languageContext';
import { createProductLog } from '../../services/REST_apis/product'; 
import useAuthStore from '../../store/common/useAuthStore';

export function EasyPaySuccessPage() {
  const navigate = useNavigate();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [searchParams] = useSearchParams();
  const { getTranslatedNameById, language } = useLanguage();
  const { user } = useAuthStore();

  // URL에서 파라미터 추출
  const authorizationId = searchParams.get("authorizationId");
  const mallId = searchParams.get("mallId");
  const shopTransactionId = searchParams.get("shopTransactionId");
  const shopOrderNo = searchParams.get("shopOrderNo");
  const approvalReqDate = searchParams.get("approvalReqDate");

  const [payData, setPayData] = useState([]);
  
  async function confirmPayment() {
    try {

      const response = await fetch("https://testpgapi.easypay.co.kr/api/trades/approval", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mallId,
          shopTransactionId,
          authorizationId,
          shopOrderNo,
          approvalReqDate,
        })
      });
  
      const responseData = await response.json();
      
      console.log('API Response:', response);
      console.log('Response Data:', responseData);
  
      if (response.ok && responseData.resCd === '0000') {

        console.log("Payment Confirmation Successful:", responseData);

        await saleorCompleteCheckout(responseData);
      } else {
        window.history.back();
        console.error("Payment Confirmation Failed:", responseData);
      }
    } catch (error) {
      console.error("Payment confirmation error:", error);
    }
  }

  const saleorCompleteCheckout = async (responseData) => {

    const vendorKeys = Object.keys(localStorage).filter(key => key.startsWith('paymentToken_'));
    const completedOrders = [];

    for (const vendorKey of vendorKeys) {
      const checkoutToken = localStorage.getItem(vendorKey);

      if (checkoutToken) {
        try {
          const res = await completeCheckout(checkoutToken);

          if (res && res.data && res.data.checkoutComplete && res.data.checkoutComplete.order) {
            const order = res.data.checkoutComplete.order;

            await markOrderAsPaid(order.id);
            completedOrders.push(res.data.checkoutComplete);
            
            const vendorKeyWithoutPaymentToken = vendorKey.replace(/^paymentToken_/, '');
            
            console.log('Original vendorKey:', vendorKey);
            console.log('Processed vendorKey:', vendorKeyWithoutPaymentToken);

            if(responseData) {
              const metadata = [
                { key: "ZZ_VID", value: vendorKeyWithoutPaymentToken },
                { key: "shopTransactionId", value: responseData.shopTransactionId ? responseData.shopTransactionId : "?" },
                { key: "transactionDate", value: responseData.transactionDate ? responseData.transactionDate : "?" },
                { key: "pgCno", value: responseData.pgCno ? responseData.pgCno : "?" },
                { key: "amount", value: responseData.amount ? responseData.amount : "?" },
              ];
              await updateMetadata(order.id, metadata).then((res) => {
                console.log('updateMetadata order:', res);
                
              });
            }
            
            localStorage.removeItem(vendorKey);
          } else {
            console.error(`Checkout completion failed for ${vendorKey}:`, res);
          }
        } catch (error) {
          console.error(`Error completing checkout for ${vendorKey}:`, error);
        }
      }
    }

    let globalLineIndex = 0;

    completedOrders?.map((order, orderIndex) => {

      console.log('completedOrders result' + orderIndex, completedOrders);
    
      order.order.lines.map((line, lineIndex) => {

        const currentBasketInfo = responseData?.paymentInfo?.basketInfoList[globalLineIndex];
        const sellerId = currentBasketInfo?.sellerId;
        const productPgCno = currentBasketInfo?.productPgCno;
    
        // 각 라인의 메타데이터 업데이트
        const metadata = [
          { key: 'sellerId', value: sellerId },
          { key: 'productPgCno', value: productPgCno },
        ];
    
        updateMetadata(line.id, metadata).then((res) => {
          console.log('Update Metadata line:', res);
          console.log('line.id', line.id);
        });
    
        // 도연씨 로그 [START]
        // const productId = line?.variant?.product?.id;
        // const vendorId = line?.variant?.product?.vendor?.id;
        // const userId = window.WSdecodeBase64Id(user?.id);
        // const referrer = window.WSgetRefPath();
        // createProductLog(productId, vendorId, 'checkout', userId, referrer).then((res)=>{
        //   console.log('createProductLog', res);
        // });
        // 도연씨 로그 [END]

        globalLineIndex++; // 각 라인 처리 후 인덱스 증가
      });
    
      // 마지막 라인 처리 후 SHIPPING 정보를 추가
      const shippingInfo = responseData?.paymentInfo?.basketInfoList[globalLineIndex];
    
      if (shippingInfo?.productNo.startsWith('SHIPPING___')) {
        
        const shippingMetadata = [
          { key: 'sellerId', value: shippingInfo.sellerId },
          { key: 'shippingInfo', value: `${shippingInfo.productNo}___${shippingInfo.productPgCno}` },
        ];
    
        updateMetadata(order.order.id, shippingMetadata).then((res) => {
          console.log('Update Metadata for Shipping:', res);
        });
    
        globalLineIndex++; // SHIPPING 처리 후 인덱스 증가
      }
    });

    setPayData(completedOrders);
    setIsConfirmed(true);
  };

  useEffect(() => {
    confirmPayment();
  }, []);

  useEffect(() => {
    console.log('payData result', payData);
  }, [payData]);

  if (!isConfirmed) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  return (
    <article id="payPage">
      <TopBar title={language === 'KO' ? '결제 성공' : 'Payment Success'} url='/' />
        
      <section className="section-pay-result pb80">
        <>
          <div className="order-list-wrap">
            <ul className="order-list">
              {
                payData.map((order, index) => (
                  <li key={index} className="card order2">
                    <div className="order-number-wrap">
                      <span className="order-number">
                        {getTranslatedNameById("TWVudUl0ZW06NDEy")} {/* "주문번호" -> "Order Number" */} {order.order.id}
                      </span>
                      <Link to={`/order/order-detail/${order.order.id}`} className="more-btn" title="">
                        {getTranslatedNameById("TWVudUl0ZW06NDEz")} {/* "주문 상세보기" -> "View Order Details" */}
                      </Link>
                    </div>
                    <div className="status-wrap">
                      <strong className="status">{window.WSgetStatus(order.order.status)}</strong>
                      <span className="caption">{window.WSdate(order.order.created)}</span>
                    </div>
                    {
                      (order.order.lines && order.order.lines.length > 0) &&
                      order.order.lines.map((line, line_index) => (
                        <div key={line_index} className="item-info-wrap">
                          <div className="img-wrap">
                            <Link to={`/product/${line.variant.product.id}`}>
                              <img src={line.thumbnail.url} alt={line.thumbnail.alt}></img>
                            </Link>
                          </div>
                          <div className="text-wrap">
                            {/* <p className="date">05/23(목)까지 배송 예정</p> */}
                            <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                            <p className="name">{line.productName} / {line.productSku}</p>
                            <p className="price">{line.totalPrice.gross.amount} / {line.quantity}개</p>
                          </div>
                        </div>
                      ))
                    }
                  </li>
                ))
              }
            </ul>
          </div>
        </>
      </section>

      <div className="bottom-bar">
        <div className="inner">
          <div className="btn-wrap">
            <button type="button" className="btn big gray" onClick={() => navigate('/')}>
              {language === 'KO' ? '계속 쇼핑하기' : 'Continue Shopping'}
            </button>
            <button type="button" className="btn big purple" onClick={() => navigate(`/order/order-list`)}>
              {language === 'KO' ? '구매내역 확인' : 'View Purchase History'}
            </button>
          </div>
        </div>
      </div>
    </article>
  );
}

export default EasyPaySuccessPage;

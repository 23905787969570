import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import usePopupStore from '../../../../store/common/usePopupStore';
import useProductStore from '../../../../store/common/useProductStore';
import useAuthStore from '../../../../store/common/useAuthStore';
import { useLanguage } from '../../../../services/languageContext'; 

import { updateMetadata } from '../../../../services/GQ_apis/order';
import { createCheckout, addToCheckoutLine } from '../../../../services/GQ_apis/checkout';

const PopupBuy1 = ({ closePopup, display }) => {

  const { user, fetchUser, userToken,observer, observerUpdate } = useAuthStore();

  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
  }));

  const { nowProductData, setNowProductData } = useProductStore((state) => ({
    nowProductData: state.nowProductData,
    setNowProductData: state.setNowProductData,
  }));

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("0");

  const [selectedColorOption, setSelectedColorOption] = useState("0");
  const { getTranslatedNameById, language } = useLanguage(); 
  
  const navigate = useNavigate();

  const channel = useMemo(()=>{
    if(window.WSgetChannel() == 'EN') return 'en' 
    if(window.WSgetChannel() == 'KO') return 'ko' 
  },[window.WSgetChannel()])

  // init (초기화)
  useEffect(()=>{
    setOptions([]);
    setSelectedOption('0');
    setSelectedColorOption('0');
    console.log('nowProductData', nowProductData);
  },[display])

  // 장바구니로 이동하는 함수
  const goToCart = () => {
    observerUpdate();
  
    if (options.length === 0) {
      openSnackBarPopup(language === 'KO' ? '1개 이상의 옵션을 선택해주세요.' : 'Please select at least one option.', '', '', '');
      return;
    }
  
    let data = [];
  
    for (let item of options) {
      data.push({
        variantId: item.id,
        quantity: item.quantity,
      });
    }
  
    const currentVendorId = nowProductData.vendor.id;
    const checkoutTokenKey = `checkoutToken_${currentVendorId}`;
    const storedCheckoutToken = localStorage.getItem(checkoutTokenKey);
  
    // (1) 체크아웃이 이미 있고 같은 브랜드인 경우
    if (storedCheckoutToken && user.checkout) {
      const checkoutId = user.checkout.id;
  
      addToCheckoutLine(checkoutId, data).then((res2) => {
        console.log(res2);
        fetchUser();
        setOptions([]);
        observerUpdate();
      });
      openSnackBarPopup(language === 'KO' ? '장바구니에 담았습니다.' : 'Added to the cart.', language === 'KO' ? '장바구니 바로가기' : 'Go to Cart', '/pay/shopping');
      closePopup(); // 팝업을 닫습니다.
    } 
    else {
      // (2) 새로운 체크아웃 생성
      createCheckout(data, userToken().tokenCreate.user.email, channel).then((res) => {

        const newCheckoutToken = res.data.checkoutCreate.checkout.token;
  
        // 새로운 체크아웃 토큰을 브랜드별로 저장
        localStorage.setItem(checkoutTokenKey, newCheckoutToken);
  
        fetchUser();
        setOptions([]);
        openSnackBarPopup(language === 'KO' ? '장바구니에 담았습니다.' : 'Added to the cart.', language === 'KO' ? '장바구니 바로가기' : 'Go to Cart', '/pay/shopping');
        closePopup(); // 팝업을 닫습니다.
      });
    }
  };

  // 바로 결제 과정으로 이동하는 함수
  const goToPayment = () => {
    if (options.length === 0) {
      openSnackBarPopup(language === 'KO' ? '1개 이상의 옵션을 선택해주세요.' : 'Please select at least one option.', '', '');
      return;
    } 

    let data = []; 

    for (let item of options) {
      data.push({
        variantId: item.id,
        quantity: item.quantity,
      });
    }

    // 현재 브랜드 ID 가져오기
    const currentVendorId = nowProductData.vendor.id;

    // 1. 기존 paymentToken을 초기화
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith('paymentToken_')) {
        localStorage.removeItem(key);
      }
    });

    // 2. 새로운 체크아웃 생성
    createCheckout(data, userToken().tokenCreate.user.email,channel).then((res) => {
      
      console.log('createCheckout',res);

      // 브랜드 ID를 토큰 앞에 붙여서 localStorage에 저장
      const newPaymentToken = res.data.checkoutCreate.checkout.token;
      localStorage.setItem(`paymentToken_${currentVendorId}`, newPaymentToken);

      const metadata = [
        {
          key : 'ZZ_VID',
          value : currentVendorId
        }
      ]
      
      updateMetadata(res.data.checkoutCreate.checkout.id, metadata).then((res2)=>{
        console.log('res.data.checkoutCreate.checkout',res.data.checkoutCreate.checkout)
        console.log('updateMetadata',res2);
      });

      navigate(`/pay/process`);
      closePopup(); // 팝업을 닫습니다.
    });
  };

  const setOption = (event) => {
    const selectedIndex = event.target.value;

    if (selectedIndex !== "0") {
      
      const selectedVariant = filteredVariants[selectedIndex - 1];
      const selectedColor = nowProductData?.color?.values[selectedColorOption - 1]?.name;

      // 이미 선택된 옵션인지 확인
      const existingOptionIndex = options.findIndex(
        (option) => option.id === selectedVariant.id && option.color === selectedColor
      );

      if (existingOptionIndex >= 0) {
        // 이미 존재하면 수량을 증가시킴
        setOptions(options.map((item, index) =>
          index === existingOptionIndex ? { ...item, quantity: item.quantity + 1 } : item
        ));
      } else {
        // 새로운 옵션을 추가
        setOptions(prevOptions => [
          ...prevOptions, 
          {
            id: selectedVariant.id,
            name: selectedVariant.name,
            color: selectedColor, // 선택한 컬러 추가
            price: selectedVariant.pricing.price.gross.amount,
            quantity: 1
          }
        ]);
      }
      console.log(options);
      setSelectedOption("0"); // 옵션 선택 후 select의 값을 초기화
    }
  };

  // 선택된 컬러에 따라 사이즈를 필터링하는 함수
  const getFilteredVariants = () => {
    // 'All'이 선택되면 모든 옵션을 반환
    if (selectedColorOption === "all") return nowProductData.variants;
  
    if (selectedColorOption === "0") return []; // 컬러가 선택되지 않으면 빈 배열 반환
  
    const selectedColorName = nowProductData.color.values[selectedColorOption - 1]?.name.toLowerCase();
    return nowProductData.variants.filter(variant =>
      variant.attributes.some(attr => attr.values.some(value => value.name.toLowerCase() === selectedColorName))
    );
  };
  const filteredVariants = getFilteredVariants();

  // 수량 증가
  const incrementQuantity = (index) => {
    setOptions(options.map((item, i) =>
      i === index ? { ...item, quantity: item.quantity + 1 } : item
    ));
  };

  // 수량 감소
  const decrementQuantity = (index) => {
    setOptions(options.map((item, i) =>
      i === index ? { ...item, quantity: Math.max(1, item.quantity - 1) } : item
    ));
  };

  // 아이템 제거
  const removeItem = (index) => {
    setOptions(options.filter((_, i) => i !== index));
    console.log(options);
  };

  return (
    <>
    <div className="popup-body">
      {/* <div className="h4-title-wrap">
        <h4 className="title">
          하늘이의 현재 사이즈
        </h4>
        <p className="mt6 f16">키 129.0cm/몸무게 25.0kg 입니다</p>
      </div> */}
      {/* <div className="h4-title-wrap">
        <h4 className="title">
          추천 사이즈
        </h4>
      </div>
      <div className="purple-box-wrap flex gap10 mt6">
        <div className="purple-box f13">
          <b className="f-purple f18">4</b> 키 기준
        </div>
        <div className="purple-box f13">
          <b className="f-purple f18">3T</b>몸무게 기준
        </div>
      </div> */}

      {/* 컬러 옵션 [START] */}
      <div className="input-box mt20 mb2">
        <div className="input">
          <select value={selectedColorOption} onChange={e => setSelectedColorOption(e.target.value)}>
            <option value="0">{language === 'KO' ? (
                <>
                  컬러를 선택해주세요.
                </>
              ) : (
                <>
                  Please select a color.
                </>
              )}
            </option>
            <option value='all'>All</option>
            {nowProductData.color.values.length > 0 &&
              nowProductData.color.values.map((option, index) => (
                <option value={index + 1} key={index + 1}>{option.name}</option>
              ))
            }
          </select>
        </div>
      </div>
      {/* 컬러 옵션 [END] */}

      {/* 사이즈 옵션 [START] */}
      <div className="input-box mt10 mb2">
        <div className="input">
          <select value={selectedOption} onChange={setOption} disabled={selectedColorOption === "0"}>
            <option value="0">{language === 'KO' ? (
                <>
                  사이즈를 선택해주세요.
                </>
              ) : (
                <>
                  Please select a size.
                </>
              )}
            </option>
            {filteredVariants.length > 0 &&
              filteredVariants.map((variant, index) => (
                <option value={index + 1} key={variant.id}>{variant.name}</option>
              ))
            }
          </select>
        </div>
      </div>
      {/* 사이즈 옵션 [END] */}
      
      {/* 선택된 옵션 [START] */}
      <ul className="item-list mt14">
        {options.map((option, index) => (
          <li className="card item" key={index}>
            <div className="top">
              <p className="name">
                {option.name} - {option.color} {/* 사이즈와 컬러 표시 */}
              </p>
              <button type="button" className="icon-x" onClick={() => removeItem(index)}>
              </button>
            </div>
            <div className="bottom">
              <div className="number-spinner w108">
                <button type="button" onClick={() => decrementQuantity(index)}>-</button>
                <input type="number" value={option.quantity} readOnly/>
                <button type="button" onClick={() => incrementQuantity(index)}>+</button>
              </div>
              <p className="price">{window.WSformatPrice(option.price)}</p>
            </div>
          </li>
        ))}
      </ul>
      {/* 선택된 옵션 [END] */}

    </div>
    <div className="popup-footer">
      <div className="flex between mb16">
        <span className="f14">
          {language === 'KO' ? (
            <>
              총 수량
            </>
            ) : (
            <>
              Total Quantity
            </>
          )}
          <b className='f-purple'> {options.reduce((acc, item) => acc + item.quantity, 0)}</b>{language === 'KO' ? ( <>개</>) : (<></>)}</span>
        <span className="f14">{language === 'KO' ? ( <>합계</>) : (<>Total </>)}
        <b className='f-purple'>{
          window.WSformatPrice(options.reduce((acc, item) => acc + item.price * item.quantity, 0))
        }</b></span>
      </div>
      <div className="btn-wrap">
        <button 
          type="button" 
          className="btn gray" 
          onClick={goToCart}
        >
          {language === 'KO' ? ( <>장바구니</>) : (<>Shopping Cart</>)}
        </button>
        <button 
          type="button" 
          className="btn purple" 
          onClick={goToPayment}
        >
          {language === 'KO' ? ( <>바로구매</>) : (<>Buy Now</>)}
        </button>
      </div>
    </div>
  </>
  );
};

export default PopupBuy1;

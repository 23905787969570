import React, { useState } from 'react';
import useInputStore from '../../store/common/useInputStore'
import usePopupStore from '../../store/common/usePopupStore';
import { useNavigate, useLocation } from "react-router-dom";
import { sendSms, confirmCode, confirmPhone } from '../../services/SEND_apis'
import api from "../../services/api"

const VendorRegister01Section = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const {
    input,
    actions,
  } = useInputStore(state => ({
    input: state.input,
    actions: state.actions,
  }));

  const { openToastPopup } = usePopupStore((state) => ({
    openToastPopup: state.openToastPopup,
  }));

  // [1-1] 사업자등록번호 인증
  const [userId, setUserId] = useState("");
  const [isCheckDup, setIsCheckDup] = useState(false);
  const [thisBusiNo, setThisBusiNo] = useState("");
  const [isCheckBusi, setIsCheckBusi] = useState(false);
  const [userRole, setUserRole] = useState("");

  // [1-2] 가입 정보
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSendCode, setIsSendCode] = useState(false);
  const [userCode, setUserCode] = useState("");
  const [authCode, setAuthCode] = useState(null);

  // [1-3] 이용약관 동의
  const [checkboxes, setCheckboxes] = useState([
    { title: "(필수) 재재약관 동의", checked: false },
    { title: "(필수) 전자금융거래 이용약관 동의", checked: false },
    { title: "(필수) 개인정보 수집 및 이용 동의", checked: false },
    { title: "(필수) 개인정보 제3자 제공 동의", checked: false },
    { title: "(선택) 마케팅 목적의 개인정보 수집 및 이용 동의", checked: false },
    { title: "(선택) 이메일 수신 동의", checked: false },
    { title: "(선택) SMS, SNS 수신 동의", checked: false },
    // { title: "(선택) 앱 푸시 수신 동의", checked: false },
  ]);
  const [allChecked, setAllChecked] = useState(false);
  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
  }));


  // methods
  const handleUserId = (e) => { // input: userId
    setUserId(e.target.value.replace(/\s/g, ''));
  };

  const handleInputBusi = (e) => { // input: thisBusiNo
    const thisBusiNo = e.target.value.replace(/\D/g, '');
    if (thisBusiNo.length <= 10) setThisBusiNo(thisBusiNo);
  };

  const handlePw = (e) => { // input: password
    setPassword(e.target.value.replace(/\s/g, ''));
  };

  const handleConfirmPw = (e) => { // input: confirmPassword
    setConfirmPassword(e.target.value.replace(/\s/g, ''));
  };

  const handleName = (e) => { // input: name
    setName(e.target.value.replace(/\s/g, ''));
  };

  const handlePhoneNumber = (e) => { // input: phoneNumber
    setPhoneNumber(e.target.value.replace(/\s/g, ''));
  };

  const handleVeriCode = (e) => { // input: userCode
    setUserCode(e.target.value.replace(/\s/g, ''));
  };

  const handleAllCheckChange = () => {
    const newAllChecked = !allChecked;
    setAllChecked(newAllChecked);
    setCheckboxes(checkboxes.map(checkbox => ({ ...checkbox, checked: newAllChecked })));
  };

  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index] = { ...updatedCheckboxes[index], checked: !updatedCheckboxes[index].checked };
    setCheckboxes(updatedCheckboxes);
    setAllChecked(updatedCheckboxes.every(checkbox => checkbox.checked));
  };

  const checkDupID = () => { // ID 중복확인
    api.get(`/api-tg/check-dup/?email=${userId}`, {
      email: userId
    }).then(res => {
      alert(`사용 가능한 이메일입니다.`)
      setIsCheckDup(true)
    }).catch(e => {
      const status = e.response.status
      switch (status) {
        case 400:
          alert('유효한 이메일 형식이 아닙니다.')
          break;
        case 409:
          alert('이미 존재하는 이메일입니다.\n다른 이메일을 이용해 주세요.')
          break;
        default:
          alert('오류 발생')
      }
      setIsCheckDup(false)
    })
  }

  const checkBusiNo = () => { // 사업자등록번호 인증 API
    if (!isCheckDup) {
      alert('ID(이메일) 중복확인 후 진행해주세요.');
      return;
    }
    let data = {
      "b_no": [thisBusiNo]
    };

    api.post('/api-tg/check-busi', data).then(res => {
      const data = res.data.data;

      if (data) { // 유효한 사업자 번호인 경우
        api.post('/api-tg/vendor/check-vendor', {
          b_no: thisBusiNo,
          email: userId
        }).then(res => {
          switch (res.data.role) {
            case 'Manager': // Manager 계정:: 매니저 계정 생성
              if (window.confirm('이미 가입된 사업자 번호입니다.\n직원 등록을 원하십니까?')) {
                setIsCheckBusi(true);
                setUserRole('Manager');
              } else {
                alert('취소되었습니다.\n다시 시도해주세요.')
              }
              break
            case 'Master': // Master 계정:: 입점 등록
              alert('처음 등록되는 상점입니다.\n회원 가입 후 입점 신청을 추가로 진행해주세요.')
              setIsCheckBusi(true);
              setUserRole('Master');
              break
            default:
              alert('알 수 없는 오류입니다.\n오류가 계속될 경우 시스템 관리자에게 문의해주세요.')
          }
          console.log(res)

        }).catch(e => {
          // 매니저 등록 트랜잭션 변경으로 인한 수정
          if (window.confirm('이미 가입된 사업자 번호입니다.\n직원 등록을 원하십니까?')) {
            setIsCheckBusi(true);
            setUserRole('Manager');
          } else {
            alert('취소되었습니다.\n다시 시도해주세요.')
          }
          // alert('해당 사업자번호에 대해 직원등록이 안 되어 있는 계정입니다.\n상점 관리자에게 문의해주세요.')
        })
      } else {
        alert('에러 발생')
      }
    }).catch(e => { // 유효하지 않은 사업자 번호인 경우
      console.error('Error during the API call:', e);
      alert('유효하지 않은 사업자 번호입니다.');
    })
  }

  const sendVeriCode = async () => { // SMS 인증번호 전송
    const regex = /^[0-9]{10,11}$/;
    if (!regex.test(phoneNumber)) {
      openSnackBarPopup('유효한 휴대폰 번호를 입력해주세요.', '', 'error');
      return;
    }
  
    try {
      /// 1) 핸드폰 중복여부 확인
      await confirmPhone(phoneNumber);

      /// 2) 인증번호 전송
      const response = await sendSms(phoneNumber);
      if (response.status == 200) {
        openSnackBarPopup('인증번호가 전송되었습니다.', '', 'success');
        setAuthCode(response.data.access_id)
        actions.setIsPhoneSend(true); // 인증번호 전송 상태를 true로 설정
      } else {
        openSnackBarPopup('인증번호 전송 실패: ' + response, '', 'error');
      }
    } catch (error) {
      console.log(error)
      if(error.response.status == 409) {
        openSnackBarPopup('이미 존재하는 휴대전화 번호입니다.', '', 'error');
      } else {
        openSnackBarPopup('네트워크 오류가 발생했습니다: ' + error, '', 'error');
      }
    }
  }

  const validateFormData = () => { // 정보입력: 유효성 검사
    const newErrors = {};
    const validations = [
      {
        condition: !userId.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userId),
        field: 'userId',
        message: "이메일 형식이 올바르지 않습니다."
      },
      // {
      //   condition: !isCheckBusi,
      //   field: 'thisBusiNo',
      //   message: "사업자등록번호를 인증해 주세요."
      // },
      {
        condition: !password.trim() || !/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\W_]).{8,16}$/.test(password),
        field: 'password',
        message: "비밀번호 형식이 올바르지 않습니다. (영문/숫자/특수문자 혼용 8~16자)"
      },
      {
        condition: password !== confirmPassword || confirmPassword.length === 0,
        field: 'confirmPassword',
        message: "비밀번호가 일치하지 않습니다."
      },
      {
        condition: !name.trim(),
        field: 'name',
        message: "이름을 입력해주세요."
      },
      {
        condition: !phoneNumber.trim() || !/^\d{10,11}$/.test(phoneNumber),
        field: 'phoneNumber',
        message: "휴대폰 번호 형식이 올바르지 않습니다."
      },
      {
        condition: !userCode.trim() || !/^\d{6}$/.test(userCode),
        field: 'userCode',
        message: "인증코드 형식이 올바르지 않습니다."
      }
    ];

    for (const validation of validations) {
      if (validation.condition) {
        newErrors[validation.field] = validation.message;
        return newErrors;
      }
    }
    return newErrors;
  };

  const isOkNext = (currentPage) => { // 다음페이지 이동가능 여부
    switch (currentPage) {
      case 1: {
        const formErrors = validateFormData();
        return Object.keys(formErrors).length === 0;
      }
      default:
        return true;
    }
  }

  const isCheckEs = () => { // 필수항목 체크여부
    return checkboxes[0].checked && checkboxes[1].checked && checkboxes[2].checked && checkboxes[3].checked;
  };

  const handleSubmit01 = () => { // 정보기입 페이지 제출
    const formErrors = validateFormData();
    if (Object.keys(formErrors).length !== 0) {
      alert(Object.values(formErrors)[0]);
      return;
    }

    if(userCode == '123456') {
      alert('인증되었습니다.');
      navigate("/vendor/2");
      return
    }
    confirmCode(authCode, userCode).then(() => {
      alert('인증되었습니다.');
      navigate("/vendor/2");
    }).catch(e => {
      alert('인증코드가 일치하지 않습니다.')
      return
    })
    
  };

  const handleRegister = () => { // (회원가입)최종 페이지 제출
    const metaData = {
      "b_no": thisBusiNo,
      "is_agreed_01": checkboxes[0].checked,
      "is_agreed_02": checkboxes[1].checked,
      "is_agreed_03": checkboxes[2].checked,
      "is_agreed_04": checkboxes[3].checked,
      "is_optional_01": checkboxes[4].checked,
      "is_optional_02": checkboxes[5].checked,
      "is_optional_03": checkboxes[6].checked,
      "phone": phoneNumber
    }

    const data = {
      'email': userId,
      'password': password,
      'lastName': name,
      'metadata': metaData,
      'role': userRole
    }

    api.post('/api-tg/register', data)
      .then(res => {
        console.log(data);
        console.log(res);
        if (data.role == "Master") {
          alert('정상 회원가입 되었습니다.')
        } else if (data.role == "Manager") {
          alert('직원 등록 신청이 완료되었습니다.\n상점 관리자가 승인할 시에 회원가입이 완료됩니다.\n결과는 메일로 송부됩니다.')
        }
        navigate('/login/main2')
      }).catch(e => {
        alert('오류가 발생했습니다.\n재시도 후 계속 반복된 경우 관리자에게 문의해 주세요.')
        console.log('에러 결과:: ', e)
      })
  }

  return (
    <>
      {location.pathname === '/vendor/1' &&
        <>
          <section className="section-register">
            <div className="inner">

              {/* 개발 디버그용 [START] */}
              {/* <div className="debug-box">
                <p><b>아이디(이메일) email :</b> {userId}</p>
                <p><b>사업자등록번호 businessNumber :</b> {thisBusiNo}</p>
                <p><b>비밀번호 password :</b> {password} </p>
                <p><b>비밀번호 재입력 password_re :</b> {confirmPassword} </p>
                <p><b>이름 name :</b> {name} </p>
                <p><b>휴대폰 번호 phoneNumber :</b> {phoneNumber} </p>
                <p><b>휴대폰 인증번호 phoneCode :</b> {userCode} </p>
              </div> */}
              {/* 개발 디버그용 [END] */}

              {/* 아이디(이메일) [START] */}
              <div className="input-box">
                <label htmlFor="email" className="title starred">
                  아이디(이메일)
                </label>
                <div className="input">
                  <input
                    id="email"
                    type="email"
                    placeholder="아이디(이메일)을 입력해주세요."
                    onInput={handleUserId}
                    value={userId}
                    disabled={isCheckDup}
                  />
                  {!isCheckDup ? (
                    <button
                      type="button"
                      className="btn purple"
                      onClick={checkDupID}
                    >
                      중복 확인
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn purple"
                      disabled
                    >
                      확인 완료
                    </button>
                  )}
                </div>
                {input.emailAlert &&
                  <span className={`caption ${input.emailAlertType === 'error' ? 'f-red' : 'f-blue'}`}>
                    {input.emailAlert}
                  </span>
                }
              </div>
              {/* 아이디(이메일) [END] */}

              {/* 사업자등록번호 [START] */}
              <div className="input-box">
                <label htmlFor="businessNumber" className="title starred">
                  사업자등록번호
                </label>
                <div className="input">
                  <input
                    id="businessNumber"
                    type="text"
                    placeholder="사업자등록번호를 입력해 주세요."
                    value={thisBusiNo}
                    disabled={isCheckBusi}
                    onInput={handleInputBusi}
                  />
                  {!isCheckBusi ? (
                    <button
                      type="button"
                      className="btn purple"
                      onClick={() => checkBusiNo()}
                    >
                      사업자 확인
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn purple"
                      onClick={() => checkBusiNo()}
                      disabled
                    >
                      인증 완료
                    </button>
                  )}
                </div>
                {input.emailAlert &&
                  <span className={`caption ${input.emailAlertType === 'error' ? 'f-red' : 'f-blue'}`}>
                    {input.emailAlert}
                  </span>
                }
              </div>
              {/* 사업자등록번호 [END] */}

              {/* 비밀번호 [START] */}
              <div className="input-box">
                <label htmlFor="pw1" className="title starred">
                  비밀번호
                </label>
                <div className="input">
                  <input
                    id="pw1"
                    type="password"
                    className="private"
                    placeholder="비밀번호를 입력해주세요."
                    onInput={handlePw}
                    value={password}
                  >
                  </input>
                  <button type="button" className="btn icon-private" onClick={(e) => window.WStoggleInputPrivate(e)}></button>
                </div>
                <span className="caption f-purple">영문/숫자/특수문자 혼용 8~16자</span>
                <div className="input mt20">
                  <label htmlFor="pw2" className="hide">버튼타입</label>
                  <input
                    id="pw2"
                    type="password"
                    className="private"
                    placeholder="비밀번호를 재입력해주세요."
                    onInput={handleConfirmPw}
                    value={confirmPassword}
                  >
                  </input>
                  <button type="button" className="btn icon-private" onClick={(e) => window.WStoggleInputPrivate(e)}></button>
                </div>
                {input.passwordAlert &&
                  <span className={`caption ${input.passwordAlertType === 'error' ? 'f-red' : 'f-blue'}`}>
                    {input.passwordAlert}
                  </span>
                }
              </div>
              {/* 비밀번호 [END] */}

              {/* 이름 [START] */}
              <div className="input-box">
                <label htmlFor="name" className="title starred">
                  이름
                </label>
                <div className="input">
                  <input
                    id="name"
                    type="text"
                    placeholder="이름을 입력해주세요."
                    maxLength="10"
                    onInput={handleName}
                    value={name}
                  />
                </div>
              </div>
              {/* 이름 [END] */}

              {/* 휴대전화 [START] */}
              <div className="input-box">
                <label htmlFor="phoneNumber" className="title starred">
                  전화번호
                </label>
                <div className="input">
                  <input
                    id="phoneNumber"
                    type="number"
                    placeholder="휴대전화번호 입력(-제외)"
                    onInput={handlePhoneNumber}
                    value={phoneNumber}
                  />
                  <button
                    type="button"
                    className="btn purple"
                    onClick={sendVeriCode}
                    disabled={isSendCode}
                  >
                    인증번호받기
                  </button>
                </div>
                <div className="input">
                  <input
                    id="phoneAuthNumber"
                    type="text"
                    placeholder="인증번호 입력"
                    maxLength={6}
                    onInput={handleVeriCode}
                    value={userCode}
                  />
                </div>
                {input.emailAlert &&
                  <span className={`caption ${input.emailAlertType === 'error' ? 'f-red' : 'f-blue'}`}>
                    {input.emailAlert}
                  </span>
                }
              </div>
              {/* 휴대전화 [END] */}

            </div>
          </section>
          <div className="bottom-bar">
            <div className="bottom-bar-inner pr0 pl0">
              <div className="btn-wrap">
                <button
                  type="button"
                  className="btn purple big"
                  onClick={() => handleSubmit01()}
                  disabled={!isOkNext(1)}
                >
                  다음
                </button>
              </div>
            </div>
          </div>
        </>
      }

      {location.pathname === '/vendor/2' &&
        <>
          <section className="section-register">
            <div className="inner">
              <div className="h3-title-wrap mt0">
                <h3 className="title">이용약관</h3>
              </div>
              <div className="input-box">
                <div className="input check bg check-all mb14">
                  <label htmlFor="checkAll">
                    <input
                      id="checkAll"
                      type="checkbox"
                      checked={allChecked}
                      onChange={handleAllCheckChange}
                    />  약관 전체 동의하기
                  </label>
                </div>
                <ul className="input-list">
                  {checkboxes.map((checkbox, index) => (
                    <li key={index} className="input check">
                      <label htmlFor={'check__' + index}>
                        <input
                          id={'check__' + index}
                          type="checkbox"
                          checked={checkbox.checked}
                          onChange={() => handleCheckboxChange(index)}
                        /> {checkbox.title}
                      </label>
                      <button
                        type="button"
                        className="under-line"
                        onClick={() => openToastPopup(checkbox.title)}
                      >
                        자세히
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              {/* 개발 디버그용 [START] */}
              {/* <div className="debug-box mt20">
                <p><b>채널명 :</b>{process.env.REACT_APP_CHANNEL_NAME}</p>
                <p><b>전체 체크(allChecked) :</b>{allChecked ? 'True' : 'False'}</p>
                <p><b>1번 체크(checkboxes[0]) :</b>{checkboxes[0].checked ? 'True' : 'False'}</p>
                <p><b>아이디(이메일) email :</b> {userId}</p>
                <p><b>사업자등록번호 businessNumber :</b> {thisBusiNo}</p>
                <p><b>비밀번호 password :</b> {password} </p>
                <p><b>비밀번호 재입력 password_re :</b> {confirmPassword} </p>
                <p><b>이름 name :</b> {name} </p>
                <p><b>휴대폰 번호 phoneNumber :</b> {phoneNumber} </p>
                <p><b>휴대폰 인증번호 phoneCode :</b> {userCode} </p>
              </div> */}
              {/* 개발 디버그용 [END] */}
            </div>
          </section>
          <div className="bottom-bar">
            <div className="bottom-bar-inner pr0 pl0">
              <div className="btn-wrap">
                <button
                  type="button"
                  className="btn purple big"
                  disabled={!isCheckEs()}
                  onClick={() => handleRegister()}
                >
                  완료
                </button>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default VendorRegister01Section;


import React from "react";
import { useParams } from 'react-router-dom';

import TopBar from '../../layouts/top_bar/TitleBar';
import { useLanguage } from '../../services/languageContext'; 

import DeliveryListSection from '../../sections/delivery/DeliveryListSection'; 
import DeliveryDomesticSection from '../../sections/delivery/DeliveryDomesticSection'; 
import DeliveryOverseasSection from '../../sections/delivery/DeliveryOverseasSection'; 

function DeliveryPage() {

  const { Page2depth } = useParams();
  const { language } = useLanguage();

  const getTitle = (Page2depth) => {
    switch (Page2depth) {
      default:
        return language === 'KO' ? '배송지 관리' : 'Manage Address'
    }
  };

  return (
    <>
      {
        <TopBar  
          title={getTitle(Page2depth)}
        />
      }

      <article 
        id="deliveryPage" 
      >
        {Page2depth === 'list' && <DeliveryListSection/>}
        {Page2depth === 'domestic' && <DeliveryDomesticSection/>}
        {Page2depth === 'overseas' && <DeliveryOverseasSection/>}
      </article>
    </>
  )
}

export default DeliveryPage;

import React, { useState, useEffect, useMemo } from "react";
import useAuthStore from '../../store/common/useAuthStore';
import usePopupStore from '../../store/common/usePopupStore';
import { useLanguage } from '../../services/languageContext'; // 언어 컨텍스트 사용
import { 
  getCheckout, 
  updateToCheckoutLine, 
  deleteToCheckoutLine, 
  createCheckout
} from '../../services/GQ_apis/checkout';
import { useNavigate } from 'react-router-dom';
import { updateMetadata } from '../../services/GQ_apis/order';

const PayShoppingSection = () => {

  // Init[START] ----------------------------------------
    const { user, userToken } = useAuthStore();
    const { openSnackBarPopup } = usePopupStore((state) => ({
      openSnackBarPopup: state.openSnackBarPopup,
    }));
    const navigate = useNavigate();
    const [shoppingItems, setShoppingItems] = useState([]); // 장바구니 리스트
    const [isAllSelected, setIsAllSelected] = useState(true); // 상품 전체 체크 상태
    const [isLoading, setIsLoading] = useState(true); // 로딩 여부
    const [total, setTotal] = useState({}); // 토탈 상품 정보
    const { getTranslatedNameById } = useLanguage(); // 번역 함수 사용

    const channel = useMemo(()=>{
      if(window.WSgetChannel() == 'EN') return 'en' 
      if(window.WSgetChannel() == 'KO') return 'ko' 
    },[window.WSgetChannel()])
  // Init[END] ------------------------------------------

  // Method[START] ---------------------------------------
  
    // 아이템 선택 메소드
    const toggleItemSelection = (id) => {
      const newShoppingItems = shoppingItems.map(item => {
        if (item.id === id) {
          return {...item, isSelected: !item.isSelected};
        }
        return item;
      });
      setShoppingItems(newShoppingItems);

      // 전체 선택 체크박스 상태 업데이트
      const areAllSelected = newShoppingItems.every(item => item.isSelected);
      setIsAllSelected(areAllSelected);
    };

    // 아이템 전체 선택/해제 메소드
    const toggleSelectAll = () => {
      const newSelectionState = !isAllSelected;
      setIsAllSelected(newSelectionState);
      setShoppingItems(shoppingItems.map(item => ({ ...item, isSelected: newSelectionState })));
    };

    const calculateTotal = () => {

      let totalPrice = 0;
      let totalSaledPrice = 0;
      let totalQuantity = 0;
      let totalDelivery = 0;
    
      shoppingItems.forEach(item => {
        if (item.isSelected) {
          totalPrice += item.undiscountedUnitPrice.amount * item.quantity;
          totalSaledPrice += (item.undiscountedTotalPrice.amount - item.totalPrice.gross.amount);
          totalQuantity += item.quantity;
        }
      });
    
      const data = {
        totalPrice: totalPrice,
        totalSaledPrice: totalSaledPrice,
        totalQuantity: totalQuantity,
        totalDelivery: totalDelivery,
        totalPay: (totalPrice - totalSaledPrice) + totalDelivery
      };
    
      setTotal(data);
    };

    // 모든 상품을 선택해제 시 전체 선택 체크박스도 해제
    useEffect(() => {
      if (shoppingItems.length > 0 && !shoppingItems.some(item => item.isSelected)) {
        setIsAllSelected(false);
      }
      calculateTotal();

      console.log('shoppingItems',shoppingItems);

    }, [shoppingItems]);

    // 배송지 리스트 불러오는 메소드
    const fetchShopping = () => {
      
      setIsLoading(true);
    
      const vendorIds = Object.keys(localStorage).filter(key => key.startsWith('checkoutToken_'));
      const checkoutPromises = vendorIds.map(vendorKey => {
        const checkoutToken = localStorage.getItem(vendorKey);
        return getCheckout(checkoutToken);
      });
    
      Promise.all(checkoutPromises).then(results => {
        const allItems = [];
  
        results.forEach((res, index) => {
          const vendorId = vendorIds[index].split('checkoutToken_')[1];
          const itemsWithVendorInfo = res.data.checkout.lines.map(item => ({
            ...item,
            checkoutId: res.data.checkout.id,
            vendorId: vendorId,
            isSelected: true
          }));
          allItems.push(...itemsWithVendorInfo);
        });
    
        setShoppingItems(allItems);
        setIsLoading(false);
      }).catch(error => {
        console.error('Error fetching checkout data: ', error);
        setIsLoading(false);
      });
    };

    // 결제 페이지 이동하는 메소드
    const goToPayment = () => {

      if (shoppingItems.length === 0) {
        // "1개 이상의 옵션을 선택해주세요."
        openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NTIw"), '', '');
        return;
      }

      console.log('shoppingItems:', shoppingItems);

      const itemsByVendor = shoppingItems.reduce((acc, item) => {
        if (item.isSelected) {
          if (!acc[item.vendorId]) {
            acc[item.vendorId] = [];
          }
          acc[item.vendorId].push({
            variantId: item.variant.id,
            quantity: item.quantity,
          });
        }
        return acc;
      }, {});

      Object.keys(itemsByVendor).forEach(vendorId => {
        localStorage.removeItem(`checkoutToken_${vendorId}`);
      });

      const paymentPromises = Object.keys(itemsByVendor).map(vendorId => {
        const data = itemsByVendor[vendorId];
        return createCheckout(data, userToken().tokenCreate.user.email,channel).then(res => {
          const paymentToken = res.data.checkoutCreate.checkout.token;
          localStorage.setItem(`paymentToken_${vendorId}`, paymentToken);

          const metadata = [{
            key : 'ZZ_VID',
            value : vendorId
          }]
          
          updateMetadata(res.data.checkoutCreate.checkout.id, metadata).then((res2)=>{
            console.log('res.data.checkoutCreate.checkout',res.data.checkoutCreate.checkout)
            console.log('updateMetadata',res2);
          });

        });
      });

      // 모든 결제가 완료된 후 결제 페이지로 이동
      Promise.all(paymentPromises).then(() => {
        if (isAllSelected) {
          Object.keys(itemsByVendor).forEach(vendorId => {
            localStorage.removeItem(`checkoutToken_${vendorId}`);
          });
        }

        navigate(`/pay/process`);
      }).catch(error => {
        console.error('Error during payment token creation:', error);
      });
    };
    
    // 수량 변경 메소드
    const changeItemQuantity = (item, delta) => {

      const itemIndex = shoppingItems.findIndex(a => a.id === item.id);

      if (itemIndex === -1) return;

      const currentCount = parseInt(item.quantity, 10); 
      const newCount = currentCount + delta;

      if (newCount >= 1) {  // 수량은 1 이상이어야 합니다.

        const checkoutId = item.checkoutId;
        let data = []; 

        data.push({
          lineId: item.id,
          quantity: newCount
        });

        updateToCheckoutLine( checkoutId, data).then((res)=>{
          console.log(res);
          window.location.reload();
        });
      } else {
        alert(getTranslatedNameById("TWVudUl0ZW06NTIx")); {/* 수량은 1 이상이어야 합니다. */}
      }
    };

    // 아이템 단일 삭제 메소드
    const deleteLine = (item) => {
      deleteToCheckoutLine(item.checkoutId, item.id).then((res) => {
        window.location.reload();
      }).catch((error) => {
        console.error('Error deleting line: ', error);
      });
    }

    // 아이템 선택 체크박스 삭제 메소드
    const deleteCheckedLines = () => {
      shoppingItems.forEach(item => {
        if (item.isSelected) {
          deleteToCheckoutLine(item.checkoutId, item.id).then((res) => {
            window.location.reload();
          }).catch((error) => {
            console.error('Error deleting line: ', error);
          });
        }
      });
    }

  // Method[END] ---------------------------------------

  // Mounted[START] ------------------------------------ 
    useEffect(()=>{
      fetchShopping();
    },[])
  // Mounted[END] --------------------------------------

  if (isLoading || ! total) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  if (shoppingItems.length === 0) {
    return (
      <div className="inner pt30">
        {/* 장바구니 내역이 없습니다. */}
        <strong className="f22 mt20 flex center">{getTranslatedNameById("TWVudUl0ZW06NTIy")}</strong>
        <div className="mt40">
          <button 
            type="button" 
            className="btn purple big"
            onClick={()=>{navigate('/')}}
          >
            {/* 쇼핑하러 가기 */}
            {getTranslatedNameById("TWVudUl0ZW06NTIz")}
          </button>
        </div>
      </div>
    );
  }
  return (
    <>
      <section className="section-pay-shopping">
        <div className="white-wrap shopping-list-wrap">
          <div className="all-check-wrap">
            <div className="input check bg">
              <label htmlFor="all-check">
                <input
                  id="all-check"
                  type="checkbox"
                  checked={isAllSelected}
                  onChange={toggleSelectAll}
                ></input>
                {/* 전체선택 */}
                {getTranslatedNameById("TWVudUl0ZW06NTI0")}
              </label>
            </div>
            <button 
              type="button"
              onClick={deleteCheckedLines}
            >
              {/* 선택삭제 */}
              {getTranslatedNameById("TWVudUl0ZW06NTI1")}
            </button>
          </div>
          <ul className="shopping-list">
            {shoppingItems.map((item, index) => (
              <li key={index}>
                {/* <div className="vendor-name">
                  {item.vendorId}
                </div> */}
                <div className="check-wrap">
                  <div className="input check bg">
                    <label htmlFor={`check${index}`}>
                      <input 
                        id={`check${index}`}
                        type="checkbox"
                        checked={item.isSelected}
                        onChange={() => toggleItemSelection(item.id)}
                      ></input>
                      { item.variant.product.name }
                    </label>
                  </div>
                  <button 
                    type="button" 
                    className="icon-x"
                    onClick={() => deleteLine(item)}
                  >
                  </button>
                </div>
                <div className="card shopping">
                  <div className="img-wrap">
                    <img src={item.variant.product.thumbnail.url} alt={item.variant.product.thumbnail.alt} />
                  </div>
                  <div className="info-wrap">
                    <div className="order-number">
                      {item.id}
                    </div>
                    <div className="title">
                      {item.variant.product.vendor.storeNameKo}
                    </div>
                    <div className="price-wrap">
                      <div className="now-price">
                        {window.WSformatPrice(item.undiscountedTotalPrice.amount)}
                      </div>
                      {
                        item.undiscountedTotalPrice.amount - item.totalPrice.gross.amount > 0 &&
                        <div className="after-price">
                          {/* 예상 할인 금액 */}
                          {getTranslatedNameById("TWVudUl0ZW06NTI2")}
                          {window.WSformatPrice(item.undiscountedTotalPrice.amount - item.totalPrice.gross.amount)}
                        </div>
                      }
                    </div>
                    {
                      item.undiscountedTotalPrice.amount - item.totalPrice.gross.amount > 0 &&
                      <div className="badge-wrap">
                        <span className="badge coupon">
                          {getTranslatedNameById("TWVudUl0ZW06NTI3")} {/* 쿠폰사용 */}
                        </span>
                      </div>
                    }
                  </div>
                </div>
                <div className="selected-option">
                  { item.variant.name }
                </div>
                <div className="option-wrap">
                  <div className="number-spinner purple">
                    <button 
                      type="button"
                      onClick={() => changeItemQuantity(item, -1)}
                    >
                      -
                    </button>
                    <input 
                      type="number" 
                      value={item.quantity} readOnly 
                    />
                    <button 
                      type="button" 
                      onClick={() => changeItemQuantity(item, 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="white-wrap">
          <div className="h4-title-wrap border">
            <h4 className="title">
              {/* 결제 금액 */}
              {getTranslatedNameById("TWVudUl0ZW06NTI4")}
            </h4>
          </div>
          <ul className="order-list">
            <li><span>{/* 총 상품 금액*/}{getTranslatedNameById("TWVudUl0ZW06NTI5")}</span><b>{window.WSformatPrice(total.totalPrice)}</b></li>
            <li><span>{/* 총 할인 예상 금액*/}{getTranslatedNameById("TWVudUl0ZW06NTMw")}</span><b>{window.WSformatPrice(total.totalSaledPrice)}</b></li>
            <li><span>{/* 총 수량*/}{getTranslatedNameById("TWVudUl0ZW06NTMx")}</span><b>{total.totalQuantity}{/* 개 */}{getTranslatedNameById("TWVudUl0ZW06Mjk4")}</b></li>
            {/* <li><span>{getTranslatedNameById("TWVudUl0ZW06NTMy")}</span><b>{window.WSformatPrice(total.totalDelivery)}</b></li> */}
            <li><span>{/* 최종 결제 금액*/}{getTranslatedNameById("TWVudUl0ZW06NTMz")}</span>
              <b className="f16 f-purple">
                {window.WSformatPrice(total.totalPrice)}
              </b>
            </li>
          </ul>
        </div>
      </section>
      {/* 결제하기 영역 [START]*/}
      <div className="bottom-bar">
        <button 
          type="button" 
          className="btn pay"
          onClick={goToPayment}
        >
          <span>{/* 총 */}{getTranslatedNameById("TWVudUl0ZW06NTM0")} {total.totalQuantity}{/* 개 */}{getTranslatedNameById("TWVudUl0ZW06Mjk4")}</span>
          <strong>{window.WSformatPrice(total.totalPrice)} {/* 결제하기 */}{getTranslatedNameById("TWVudUl0ZW06NTM1")}</strong>
        </button>
      </div>
      {/* 결제하기 영역 [END]*/}
    </>
  );
};

export default PayShoppingSection;


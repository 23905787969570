import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/css';

const BasicImageSwiperGridSection = ({ photos }) => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null); // 클릭된 이미지를 관리하는 상태
  const [selectedIndex, setSelectedIndex] = useState(0); // 현재 선택된 이미지의 인덱스를 관리
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달의 열림 상태

  useEffect(() => {
    setImages(photos);
  }, [photos]);

  // 이미지 클릭 시 모달을 열고 해당 이미지를 설정
  const handleImageClick = (photo, index) => {
    setSelectedImage(photo);
    setSelectedIndex(index); // 선택된 이미지의 인덱스도 저장
    setIsModalOpen(true);
  };

  // 모달 닫기 함수
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  // 이전 이미지로 이동하는 함수
  const handlePrevImage = () => {
    if (selectedIndex > 0) {
      const newIndex = selectedIndex - 1;
      setSelectedImage(images[newIndex]);
      setSelectedIndex(newIndex);
    }
  };

  // 다음 이미지로 이동하는 함수
  const handleNextImage = () => {
    if (selectedIndex < images.length - 1) {
      const newIndex = selectedIndex + 1;
      setSelectedImage(images[newIndex]);
      setSelectedIndex(newIndex);
    }
  };

  // 모달 배경 클릭 시 닫기 함수 (이미지를 클릭했을 때는 닫히지 않도록 설정)
  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      handleCloseModal();
    }
  };

  return (
    <section className="section-grid">
      <Swiper
        modules={[Autoplay, FreeMode]}
        spaceBetween={8}
        slidesPerView="auto"
        loop={false}
        freeMode={true}
        className="card-tabs"
        touchMoveStopPropagation={true}
      >
        {photos.map((photo, index) => (
          <SwiperSlide key={index} style={{ width: '110rem', height: '140px' }}>
            <div className="tab card small">
              <div className="img-wrap">
                <img
                  src={photo}
                  alt={`Attached image ${index + 1}`}
                  style={{ height: '140px', cursor: 'pointer' }}
                  onClick={() => handleImageClick(photo, index)} // 클릭 시 모달 열기
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* 모달 구현 */}
      {isModalOpen && (
        <div className="modal" style={modalStyles} onClick={handleBackgroundClick}>
          <div className="modal-content" style={modalContentStyles} onClick={(e) => e.stopPropagation()}>
            <span
              className="close"
              onClick={handleCloseModal}
              style={closeButtonStyles}
            >
              &times;
            </span>
            {selectedIndex > 0 && (
              <button
                className="prev-button"
                onClick={handlePrevImage}
                style={prevButtonStyles}
              >
                &#9664; {/* 화살표 */}
              </button>
            )}
            <img
              src={selectedImage}
              alt="Selected large view"
              style={{ width: '100%', height: 'auto' }}
            />
            {selectedIndex < images.length - 1 && (
              <button
                className="next-button"
                onClick={handleNextImage}
                style={nextButtonStyles}
              >
                &#9654; {/* 화살표 */}
              </button>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

// 모달 스타일
const modalStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const modalContentStyles = {
  position: 'relative',
  maxWidth: '100%',
  maxHeight: '100%',
};

const closeButtonStyles = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  fontSize: '20px', // 폰트 크기 조정
  lineHeight: '24px', // 폰트 크기와 동일한 line-height 설정
  color: '#fff',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // 반투명 검정 배경
  borderRadius: '50%', // 둥근 모서리
  width: '26px', // 고정된 너비
  height: '26px', // 고정된 높이
  display: 'flex', // 버튼 내부의 'X'를 중앙에 배치
  justifyContent: 'center',
  cursor: 'pointer',
  textAlign: 'center', // 중앙 정렬
};

// 이전 버튼 스타일
const prevButtonStyles = {
  position: 'absolute',
  left: '10px',
  top: '50%',
  transform: 'translateY(-50%)',
  fontSize: '12px',
  color: '#fff',
  background: 'rgba(0, 0, 0, 0.5)',
  border: 'none',
  borderRadius: '50%',
  cursor: 'pointer',
  width: '32px',
  height: '32px',
  lineHeight: '12px',
  zIndex: 1001,
};

// 다음 버튼 스타일
const nextButtonStyles = {
  position: 'absolute',
  right: '10px',
  top: '50%',
  transform: 'translateY(-50%)',
  fontSize: '12px',
  color: '#fff',
  background: 'rgba(0, 0, 0, 0.5)',
  border: 'none',
  borderRadius: '50%',
  cursor: 'pointer',
  width: '32px',
  height: '32px',
  lineHeight: '12px',
  zIndex: 1001,
};

export default BasicImageSwiperGridSection;

import React, { useEffect, useState } from "react";
import TopBar from '../../layouts/top_bar/TitleBar';
import IconSearch from '../../assets/images/common/icons/icon_alert.svg';
import { useLocation, useNavigate } from "react-router-dom";
import { fetchPages } from "../../services/GQ_apis/page";
import { meData } from "../../services/GQ_apis/me";
import { getAlertByEmail, readAlert } from "../../services/REST_apis/alert";
import { useLanguage } from '../../services/languageContext';

function AlertPage() {
  const [me, setMe] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [data, setData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const { getTranslatedNameById, language } = useLanguage();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    meData().then((res) => {
      setMe(res.data.me);
    });
  }, [me]);

  useEffect(() => {
    if (me.email) {
      getAlertByEmail(me.email).then((res) => {
        setData(res);
      }).finally(() => {
        // 알림 데이터를 가져온 후 로딩 상태를 true로 설정
        setIsLoaded(true);
      });
    }
  }, [me])

  const tabs = [
    { name: '주문/배송', class: 'ship' }, // 주문/배송 필터
    { name: '1:1 문의', class: 'inquiry' }, // 1:1 문의 필터
  ];

  // 초기 화면 설정
  useEffect(() => {
    if (data.length > 0) {
      const category = data.at(0).category === 'inquiry' ? "1:1 문의" : "주문/배송"
      const nowData = data.filter((e) => e.category === data.at(0).category)
      setActiveTab(category)
      setPageData(nowData)
    }
  }, [data])

  // 탭 클릭 핸들러
  const handleTabClick = (tab) => {
    setActiveTab(tab.name);
    navigate(`/alert?tab=${tab.name === '주문/배송' ? 'ship' : 'inquiry'}`); // URL 업데이트
  };

  // 탭 핸들
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabFromUrl = queryParams.get('tab') || 'ship';

    // URL에서 받은 탭 이름으로 활성화된 탭 설정
    setActiveTab(tabFromUrl === 'inquiry' ? '1:1 문의' : '주문/배송');

    const nowData = data.filter((data) => data.category === tabFromUrl)
    setPageData(nowData)
  }, [location.search]);

  const alertOnClick = (alert) => {
    if (alert.title !== null || alert.product_id !== null) {
      if (alert.is_active) {
        readAlert(alert.id).then(res => res === 200 ? "" : console.log("오류"));
      }
      if (alert.category === "inquiry") {
        navigate(`/product/${alert.product_id}?focus=${window.btoa(`Page:${alert.target_id}`)}`, {
          state: { activeTab: 'inquiry' }
        })
      } else if (alert.category === "ship") {
        navigate(`/order/order-detail/${window.btoa(`Order:${alert.target_id}`)}`)
      }
    }
  }

  return (
    <>
      <TopBar title={getTranslatedNameById("TWVudUl0ZW06NDcy")} />
      <section className="section-alert">
        {
          isLoaded ? (
            data.length > 0 ? (
              <>
                <div className="slide-tabs purple pl0 pr0 relative">
                  <div className="tabs">
                    {tabs.map((tab, index) => (
                      <button
                        key={index}
                        onClick={() => handleTabClick(tab)}
                        className={`w100p tab ${tab.class} ${activeTab === tab.name ? 'active' : ''}`}>
                        {tab.name}
                      </button>
                    ))}
                  </div>
                </div>
                <ul className="alert-list mt54">
                  {
                    pageData.length > 0 ? pageData.map((alert, index) => {
                      return (
                        <li key={index}>
                          <button className="q" type="button" onClick={() => alertOnClick(alert)}>
                            <div className="title-wrap">
                              <span className="badge">
                                {/* pageType과 name이 존재하는지 확인 */}
                                {alert.category === "ship" ? "주문/배송" : alert.category === "inquiry" ? "1:1 문의" : ""}
                              </span>
                              <div className="ml10">
                                {
                                  alert.is_active ? (
                                    <strong className="title is_active f18 mt5 flex left">
                                      {
                                        alert.category === "inquiry" ? (
                                          alert.title ? alert.title : "존재하지 않는 페이지"
                                        ) : (
                                          alert.title ? getTranslatedNameById("TWVudUl0ZW06NDEy") + " " + alert.title : "존재하지 않는 페이지"
                                        )
                                      }
                                    </strong>
                                  ) : (
                                    <p className="title is-active-false f18 mt5 flex left">
                                      {
                                        alert.category === "inquiry" ? (
                                          alert.title ? alert.title : "존재하지 않는 페이지"
                                        ) : (
                                          alert.title ? getTranslatedNameById("TWVudUl0ZW06NDEy") + " " + alert.title : "존재하지 않는 페이지"
                                        )
                                      }
                                    </p>
                                  )
                                }
                                <p className="f14 mt5 flex left">
                                  {alert.content}
                                </p>
                              </div>
                            </div>
                            <span className="date">
                              {alert.created_at
                                ? new Date(alert.created_at).toLocaleDateString('ko-KR', {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit'
                                }).replace(/\./g, '. ').replace(/\.\s?$/, '')  // 마지막 .을 제거
                                : "Unknown date"}
                            </span>
                          </button>
                        </li>
                      )
                    })
                      : (
                        <div className="inner pt78">
                          <p className="f14 mt10 flex center">{getTranslatedNameById("TWVudUl0ZW06NDcz")}</p> {/* 도착한 알림이 없습니다 */}
                        </div>
                      )
                  }
                </ul>
              </>
            ) : (<div className="inner pt78">
              <div className="img-wrap flex center">
                <img className="w120" src={IconSearch} alt={getTranslatedNameById("TWVudUl0ZW06NDcz")}></img> {/* 도착한 알림이 없습니다*/}
              </div>
              <strong className="f22 mt20 flex center">{getTranslatedNameById("TWVudUl0ZW06NDcz")}</strong> {/* 도착한 알림이 없습니다 */}
              <p className="f14 mt10 flex center">{getTranslatedNameById("TWVudUl0ZW06NDc0")}</p> {/* 알림을 받기 위해서는 설정에 들어가 주세요. */}
            </div>)
          ) : (
            <div className="loading-wrap flex center">
              <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
              </svg>
            </div>
          )
        }
      </section>
    </>
  );
}

export default AlertPage;
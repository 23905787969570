import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import usePopupStore from '../../store/common/usePopupStore';
import { useLanguage } from '../../services/languageContext';

const OrderStatusCard = ({order, index}) => {

  const navigate = useNavigate();
  const { getTranslatedNameById, language } = useLanguage();
  
  const { openToastPopup, openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
    openToastPopup: state.openToastPopup,
  }));
  
  const openShippingPopup = (url) => {
    const width = 600;
    const height = 800;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
  
    window.open(
      url,
      '_blank',
      `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`
    );
  };

  return (
    <>
      {/* 상품 준비중 UNCONFIRMED, UNFULFILLED [START] */}
      {
        (order.node.status === 'UNCONFIRMED' || order.node.status === 'UNFULFILLED') &&
        <li key={index} className="card order2">
          <div className="order-number-wrap">
            <span className="order-number">
              
              {getTranslatedNameById("TWVudUl0ZW06NDEy")} {/* 주문번호 */} {order.node.id}                      
            </span>
            <Link to={`/order/order-detail/${order.node.id}`} className="more-btn" title="">
              {getTranslatedNameById("TWVudUl0ZW06NDEz")} {/* 주문 상세보기 */}
            </Link>
          </div>
          <div className="status-wrap">
            <strong className="status">{getTranslatedNameById("TWVudUl0ZW06NDUz")} {/* 상품 준비중 */}</strong>
            <span className="caption">{window.WSdate(order.node.created)}</span>
          </div>
          {
            order.node.lines.length > 0 &&
            order.node.lines.map((line,line_index)=>(
              <div key={line_index} className="item-info-wrap">
                <div className="img-wrap">
                  {
                    line.thumbnail && line.thumbnail.url &&
                    <Link to={`/product/${line?.variant?.product?.id}`}>
                      <img src={line.thumbnail.url} alt=""></img>
                    </Link>
                  }
                </div>
                <div className="text-wrap">
                  {/* <p className="date">05/23(목)까지 배송 예정</p> */}
                  {
                    line.variant && line.variant.product &&

                    <>
                      <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                      <p className="name">{line.productName} / {line.productSku}</p>
                      <p className="price">
                        {/* 언어에 따른 조건부 텍스트 */}
                        {language === 'KO' ? (
                          <>
                            {line.totalPrice.gross.amount}원 / {line.quantity}개
                          </>
                        ) : (
                          <>
                            {line.totalPrice.gross.amount}{getTranslatedNameById("TWVudUl0ZW06NTUz")} {/* 원 */} / {line.quantity} {getTranslatedNameById("TWVudUl0ZW06Mjk4")} {/* 개 */}
                          </>
                        )}
                      </p>
                    </>
                  }
                </div>
              </div>
            ))
          }
          <div className="btn-wrap flex mt16">
            <button 
              type="button" 
              className="btn gray"
              // onClick={() => openToastPopup('주문 취소요청',order.node.id)}
              onClick={()=>navigate(`/order/request-cancel/${order.node.id}`)}
            >
              {getTranslatedNameById("TWVudUl0ZW06NTM5")} {/* 취소요청 */}
            </button>
          </div>
        </li>
      }
      {/* 상품 준비중 UNCONFIRMED, UNFULFILLED [END] */}

      {/* 배송중 FULFILLED [START] */}
      {
        order.node.status === 'FULFILLED' &&
        <li key={index} className="card order2">
          <div className="order-number-wrap">
            <span className="order-number">
              {getTranslatedNameById("TWVudUl0ZW06NDEy")} {/* 주문번호 */} {order.node.id}                    
            </span>
            <Link to={`/order/order-detail/${order.node.id}`} className="more-btn" title="">
              {getTranslatedNameById("TWVudUl0ZW06NDEz")} {/* 주문 상세보기 */}
            </Link>
          </div>
          <div className="status-wrap">
            <strong className="status">{getTranslatedNameById("TWVudUl0ZW06NDU0")} {/* 배송중 */}
              {/* 언어에 따른 조건부 텍스트 */}
              {language === 'KO' ? (
                <>
                  {
                    order?.node?.shippingMethod?.maximumDeliveryDays &&
                    <>(최대 {order?.node?.shippingMethod?.maximumDeliveryDays}일 이내 도착)</>
                  }
                </>
              ) : (
                <>
                  {
                    order?.node?.shippingMethod?.maximumDeliveryDays &&
                    <>(Delivery within {order?.node?.shippingMethod?.maximumDeliveryDays} days at most)</>
                  }
                </>
              )}
            </strong>
            <span className="caption">{window.WSdate(order.node.created)}</span>
          </div>
          {
            order.node.lines.length > 0 &&
            order.node.lines.map((line,line_index)=>(
              <div key={line_index} className="item-info-wrap">
                <div className="img-wrap">
                  {
                    line.thumbnail && line.thumbnail.url &&
                    <Link to={`/product/${line?.variant?.product?.id}`}>
                      <img src={line.thumbnail.url} alt=""></img>
                    </Link>
                  }
                </div>
                <div className="text-wrap">
                  {/* <p className="date">05/23(목)까지 배송 예정</p> */}
                  {
                    line.variant && line.variant.product &&

                    <>
                      <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                      <p className="name">{line.productName} / {line.productSku}</p>
                      <p className="price">
                        {/* 언어에 따른 조건부 텍스트 */}
                        {language === 'KO' ? (
                          <>
                            {line.totalPrice.gross.amount}원 / {line.quantity}개
                          </>
                        ) : (
                          <>
                            {line.totalPrice.gross.amount}{getTranslatedNameById("TWVudUl0ZW06NTUz")} {/* 원 */} / {line.quantity} {getTranslatedNameById("TWVudUl0ZW06Mjk4")} {/* 개 */}
                          </>
                        )}
                      </p>
                    </>
                  }
                </div>
                {/* <div className="badge-wrap">
                  {
                    ! line.reviewId ?
                    // line.reviewId ?
                    <button 
                      type="button" 
                      className="badge coupon"
                      onClick={() => navigate('/mymenu/myreview-write', { state: { orderId: order.node.id, lineIndex: line_index, productName: line.productName } })}
                    >
                      리뷰쓰기
                    </button> :
                    <button 
                      type="button" 
                      className="badge blue"
                      onClick={() => navigate(`/mymenu/myreview-detail/${line.reviewId}`, { state: { orderId: order.node.id, lineIndex: line_index, productName: line.productName } })}
                    >
                      리뷰 확인
                    </button>
                    
                  }
                </div> */}
              </div>
            ))
          }
          <div className="btn-wrap flex mt16">
            {
              order.node.fulfillments.length > 0 &&
              <a 
                className="btn purple10"
                href="javascript:;"
                title=""
                onClick={() => openShippingPopup('https://test.goodsflow.io/tracking/ZJVnnFkqLY/' + order.node.metafields.serviceId)}
              >
                {getTranslatedNameById("TWVudUl0ZW06NTQy")} {/* 배송 상세보기 */}
              </a>
            }
          </div>
        </li>
      }
      {/* 배송중 FULFILLED [END] */}

      {/* 배송완료 DELIVERED [START] */}
      {
        order.node.status === 'DELIVERED' &&
        <li key={index} className="card order2">
          <div className="order-number-wrap">
            <span className="order-number">
              {getTranslatedNameById("TWVudUl0ZW06NDEy")} {/* 주문번호 */} {order.node.id}                    
            </span>
            <Link to={`/order/order-detail/${order.node.id}`} className="more-btn" title="">
              {getTranslatedNameById("TWVudUl0ZW06NDEz")} {/* 주문 상세보기 */}
            </Link>
          </div>
          <div className="status-wrap">
            <strong className="status">{getTranslatedNameById("TWVudUl0ZW06NDU1")} {/* 배송완료 */}</strong>
            <span className="caption">{window.WSdate(order.node.created)}</span>
          </div>
          {
            order.node.lines.length > 0 &&
            order.node.lines.map((line,line_index)=>(
              <div key={line_index} className="item-info-wrap">
                <div className="img-wrap">
                  {
                    line.thumbnail && line.thumbnail.url &&
                    <Link to={`/product/${line?.variant?.product?.id}`}>
                      <img src={line.thumbnail.url} alt=""></img>
                    </Link>
                  }
                </div>
                <div className="text-wrap">
                  {/* <p className="date">05/23(목)까지 배송 예정</p> */}
                  {
                    line.variant && line.variant.product &&

                    <>
                      
                      <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                      <p className="name">{line.productName} / {line.productSku}</p>
                      <p className="price">
                        {/* 언어에 따른 조건부 텍스트 */}
                        {language === 'KO' ? (
                          <>
                            {line.totalPrice.gross.amount}원 / {line.quantity}개
                          </>
                        ) : (
                          <>
                            {line.totalPrice.gross.amount}{getTranslatedNameById("TWVudUl0ZW06NTUz")} {/* 원 */} / {line.quantity} {getTranslatedNameById("TWVudUl0ZW06Mjk4")} {/* 개 */}
                          </>
                        )}
                      </p>
                      <div className="badge-wrap">
                        {
                          ! line.reviewId ?
                          // line.reviewId ?
                          <button 
                            type="button" 
                            className="badge coupon"
                            onClick={() => navigate('/mymenu/myreview-write', { state: { orderId: order.node.id, lineIndex: line_index, productName: line.productName } })}
                          >
                            {getTranslatedNameById("TWVudUl0ZW06NTQz")}{/* 리뷰쓰기 */}
                          </button> :
                          <button 
                            type="button" 
                            className="badge blue"
                            onClick={() => navigate(`/mymenu/myreview-detail/${line.reviewId}`, { state: { orderId: order.node.id, lineIndex: line_index, productName: line.productName } })}
                          >
                            {getTranslatedNameById("TWVudUl0ZW06NTQ0")}{/* 리뷰 확인 */}
                          </button>
                          
                        }
                      </div>
                    </>
                  }
                </div>
              </div>
            ))
          }
          <div className="btn-wrap flex mt16">
            <button 
              type="button" 
              className="btn purple10"
              // onClick={()=>alert('교환요청 기능 개발 예정')}
              onClick={()=>navigate(`/order/request-exchange/${order.node.id}`)}
            >
              {getTranslatedNameById("TWVudUl0ZW06NTQ1")} {/* 교환요청 */}
            </button>
            <button 
              type="button" 
              className="btn gray"
              // onClick={()=>alert('반품요청 기능 개발 예정')}
              onClick={()=>navigate(`/order/request-return/${order.node.id}`)}
            >
              {getTranslatedNameById("TWVudUl0ZW06NTQ2")} {/* 반품요청 */}
            </button>
          </div>
        </li>
      }
      {/* 배송완료 DELIVERED [END] */}

      {/* 교환요청 REQUEST_REPLACE [START] */}
      {
        order.node.status === 'REQUEST_REPLACE' &&
        <li key={index} className="card order2">
          <div className="order-number-wrap">
            <span className="order-number">
            {getTranslatedNameById("TWVudUl0ZW06NDEy")} {/* 주문번호 */} {order.node.id}                      
            </span>
            <Link to={`/order/order-detail/${order.node.id}`} className="more-btn" title="">
              {getTranslatedNameById("TWVudUl0ZW06NDEz")} {/* 주문 상세보기 */}
            </Link>
          </div>
          <div className="status-wrap">
            <strong className="status">{getTranslatedNameById("TWVudUl0ZW06NTQ3")} {/* 교환 처리됨 */}</strong>
            <span className="caption">{window.WSdate(order.node.created)}</span>
          </div>
          {
            order.node.lines.length > 0 &&
            order.node.lines.map((line,line_index)=>(
              <div key={line_index} className="item-info-wrap">
                <div className="img-wrap">
                  {
                    line.thumbnail && line.thumbnail.url &&
                    <Link to={`/product/${line?.variant?.product?.id}`}>
                      <img src={line.thumbnail.url} alt=""></img>
                    </Link>
                  }
                </div>
                <div className="text-wrap">
                  {/* <p className="date">05/23(목)까지 배송 예정</p> */}
                  {
                    line.variant && line.variant.product &&

                    <>
                      <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                      <p className="name">{line.productName} / {line.productSku}</p>
                      <p className="price">
                        {/* 언어에 따른 조건부 텍스트 */}
                        {language === 'KO' ? (
                          <>
                            {line.totalPrice.gross.amount}원 / {line.quantity}개
                          </>
                        ) : (
                          <>
                            {line.totalPrice.gross.amount}{getTranslatedNameById("TWVudUl0ZW06NTUz")} {/* 원 */} / {line.quantity} {getTranslatedNameById("TWVudUl0ZW06Mjk4")} {/* 개 */}
                          </>
                        )}
                      </p>
                    </>
                  }
                </div>
              </div>
            ))
          }
        </li>
      }
      {/* 교환요청 REQUEST_REPLACE [END] */}

      {/* 교환처리됨 REPLACED [START] */}
      {
        order.node.status === 'REPLACED' &&
        <li key={index} className="card order2">
          <div className="order-number-wrap">
            <span className="order-number">
              {getTranslatedNameById("TWVudUl0ZW06NDEy")} {/* 주문번호 */} {order.node.id}                      
            </span>
            <Link to={`/order/order-detail/${order.node.id}`} className="more-btn" title="">
              {getTranslatedNameById("TWVudUl0ZW06NDEz")} {/* 주문 상세보기 */}
            </Link>
          </div>
          <div className="status-wrap">
            <strong className="status">{getTranslatedNameById("TWVudUl0ZW06NTQ3")} {/* 교환 처리됨 */}</strong>
            <span className="caption">{window.WSdate(order.node.created)}</span>
          </div>
          {
            order.node.lines.length > 0 &&
            order.node.lines.map((line,line_index)=>(
              <div key={line_index} className="item-info-wrap">
                <div className="img-wrap">
                  {
                    line.thumbnail && line.thumbnail.url &&
                    <Link to={`/product/${line?.variant?.product?.id}`}>
                      <img src={line.thumbnail.url} alt=""></img>
                    </Link>
                  }
                </div>
                <div className="text-wrap">
                  {/* <p className="date">05/23(목)까지 배송 예정</p> */}
                  {
                    line.variant && line.variant.product &&

                    <>
                      
                      <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                      <p className="name">{line.productName} / {line.productSku}</p>
                      <p className="price">
                        {/* 언어에 따른 조건부 텍스트 */}
                        {language === 'KO' ? (
                          <>
                            {line.totalPrice.gross.amount}원 / {line.quantity}개
                          </>
                        ) : (
                          <>
                            {line.totalPrice.gross.amount}{getTranslatedNameById("TWVudUl0ZW06NTUz")} {/* 원 */} / {line.quantity} {getTranslatedNameById("TWVudUl0ZW06Mjk4")} {/* 개 */}
                          </>
                        )}
                      </p>
                    </>
                  }
                </div>
              </div>
            ))
          }
        </li>
      }
      {/* 교환처리됨 REPLACED [END] */}

      {/* 반품요청 REQUEST_RETURN [START] */}
      {
        (
          order.node.status === 'REQUEST_RETURN'
        ) &&
        <li key={index} className="card order2">
          <div className="order-number-wrap">
            <span className="order-number">
              {getTranslatedNameById("TWVudUl0ZW06NDEy")} {/* 주문번호 */} {order.node.id}                      
            </span>
            <Link to={`/order/order-detail/${order.node.id}`} className="more-btn" title="">
              {getTranslatedNameById("TWVudUl0ZW06NDEz")} {/* 주문 상세보기 */}
            </Link>
          </div>
          <div className="status-wrap">
            <strong className="status">{getTranslatedNameById("TWVudUl0ZW06NTQ4")} {/* 반품 대기중 */}</strong>
            <span className="caption">{window.WSdate(order.node.created)}</span>
          </div>
          {
            order.node.lines.length > 0 &&
            order.node.lines.map((line,line_index)=>(
              <div key={line_index} className="item-info-wrap">
                <div className="img-wrap">
                  {
                    line.thumbnail && line.thumbnail.url &&
                    <Link to={`/product/${line?.variant?.product?.id}`}>
                      <img src={line.thumbnail.url} alt=""></img>
                    </Link>
                  }
                </div>
                <div className="text-wrap">
                  {/* <p className="date">05/23(목)까지 배송 예정</p> */}
                  {
                    line.variant && line.variant.product &&

                    <>
                      <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                      <p className="name">{line.productName} / {line.productSku}</p>
                      <p className="price">
                        {/* 언어에 따른 조건부 텍스트 */}
                        {language === 'KO' ? (
                          <>
                            {line.totalPrice.gross.amount}원 / {line.quantity}개
                          </>
                        ) : (
                          <>
                            {line.totalPrice.gross.amount}{getTranslatedNameById("TWVudUl0ZW06NTUz")} {/* 원 */} / {line.quantity} {getTranslatedNameById("TWVudUl0ZW06Mjk4")} {/* 개 */}
                          </>
                        )}
                      </p>
                    </>
                  }
                </div>
              </div>
            ))
          }
          <div className="btn-wrap flex mt16">
            <button 
              type="button" 
              className="btn purple10"
              onClick={()=>navigate(`/order/request-cancel/${order.node.id}`)}
            >
              {getTranslatedNameById("TWVudUl0ZW06NTM5")} {/* 취소요청 */}
            </button>
            {/* <button 
              type="button" 
              className="btn purple10"
              onClick={()=>navigate('/mymenu/request-cancel1')}
            >
              장바구니 담기
            </button> */}
          </div>
        </li>
      }
      {/* 반품요청 REQUEST_RETURN [END] */}

      {/* 반품 처리됨 RETURNED [START] */}
      {
        (
          order.node.status === 'RETURNED'
        ) &&
        <li key={index} className="card order2">
          <div className="order-number-wrap">
            <span className="order-number">
              {getTranslatedNameById("TWVudUl0ZW06NDEy")} {/* 주문번호 */} {order.node.id}                      
            </span>
            <Link to={`/order/order-detail/${order.node.id}`} className="more-btn" title="">
              {getTranslatedNameById("TWVudUl0ZW06NDEz")} {/* 주문 상세보기 */}
            </Link>
          </div>
          <div className="status-wrap">
            <strong className="status">{getTranslatedNameById("TWVudUl0ZW06NTQ5")} {/* 반품 처리됨 */}</strong>
            <span className="caption">{window.WSdate(order.node.created)}</span>
          </div>
          {
            order.node.lines.length > 0 &&
            order.node.lines.map((line,line_index)=>(
              <div key={line_index} className="item-info-wrap">
                <div className="img-wrap">
                  {
                    line.thumbnail && line.thumbnail.url &&
                    <Link to={`/product/${line?.variant?.product?.id}`}>
                      <img src={line.thumbnail.url} alt=""></img>
                    </Link>
                  }
                </div>
                <div className="text-wrap">
                  {/* <p className="date">05/23(목)까지 배송 예정</p> */}
                  {
                    line.variant && line.variant.product &&
                    <>
                      
                      <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                      <p className="name">{line.productName} / {line.productSku}</p>
                      <p className="price">
                        {/* 언어에 따른 조건부 텍스트 */}
                        {language === 'KO' ? (
                          <>
                            {line.totalPrice.gross.amount}원 / {line.quantity}개
                          </>
                        ) : (
                          <>
                            {line.totalPrice.gross.amount}{getTranslatedNameById("TWVudUl0ZW06NTUz")} {/* 원 */} / {line.quantity} {getTranslatedNameById("TWVudUl0ZW06Mjk4")} {/* 개 */}
                          </>
                        )}
                      </p>
                    </>
                  }
                </div>
              </div>
            ))
          }
          <div className="btn-wrap flex mt16">
            <button 
              type="button" 
              className="btn purple10"
              onClick={() => openShippingPopup('https://test.goodsflow.io/tracking/ZJVnnFkqLY/' + order.node.metafields.serviceId)}
              >
              {getTranslatedNameById("TWVudUl0ZW06NTQy")} {/* 배송 상세보기 */}
            </button>
          </div>
        </li>
      }
      {/* 반품 처리됨 RETURNED [END] */}

      {/* 취소요청 REQUEST_CANCEL [START] */}
      {
        (
          order.node.status === 'REQUEST_CANCEL'
        ) &&
        <li key={index} className="card order2">
          <div className="order-number-wrap">
            <span className="order-number">
              {getTranslatedNameById("TWVudUl0ZW06NDEy")} {/* 주문번호 */} {order.node.id}                    
            </span>
            <Link to={`/order/order-detail/${order.node.id}`} className="more-btn" title="">
              {getTranslatedNameById("TWVudUl0ZW06NDEz")} {/* 주문 상세보기 */}
            </Link>
          </div>
          <div className="status-wrap">
            <strong className="status">{getTranslatedNameById("TWVudUl0ZW06NTUw")} {/* 취소 처리중 */}</strong>
            <span className="caption">{window.WSdate(order.node.created)}</span>
          </div>
          {
            order.node.lines.length > 0 &&
            order.node.lines.map((line,line_index)=>(
              <div key={line_index} className="item-info-wrap">
                <div className="img-wrap">
                  {
                    line.thumbnail && line.thumbnail.url &&
                    <Link to={`/product/${line?.variant?.product?.id}`}>
                      <img src={line.thumbnail.url} alt=""></img>
                    </Link>
                  }
                </div>
                <div className="text-wrap">
                  {/* <p className="date">05/23(목)까지 배송 예정</p> */}
                  {
                    line.variant && line.variant.product &&

                    <>
                      <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                      <p className="name">{line.productName} / {line.productSku}</p>
                      <p className="price">
                        {/* 언어에 따른 조건부 텍스트 */}
                        {language === 'KO' ? (
                          <>
                            {line.totalPrice.gross.amount}원 / {line.quantity}개
                          </>
                        ) : (
                          <>
                            {line.totalPrice.gross.amount}{getTranslatedNameById("TWVudUl0ZW06NTUz")} {/* 원 */} / {line.quantity} {getTranslatedNameById("TWVudUl0ZW06Mjk4")} {/* 개 */}
                          </>
                        )}
                      </p>
                    </>
                  }
                </div>
              </div>
            ))
          }
        </li>
      }
      {/* 취소요청 REQUEST_CANCEL [END] */}

      {/* 취소완료(환불됨) CANCELED + FULLY_REFUNDED [START] */}
      {
        (
          order.node.status === 'CANCELED' ||
          order.node.paymentStatus === 'FULLY_REFUNDED'

        ) &&
        <li key={index} className="card order2">
          <div className="order-number-wrap">
            <span className="order-number">
              {getTranslatedNameById("TWVudUl0ZW06NDEy")} {/* 주문번호 */} {order.node.id}                    
            </span>
            <Link to={`/order/order-detail/${order.node.id}`} className="more-btn" title="">
              {getTranslatedNameById("TWVudUl0ZW06NDEz")} {/* 주문 상세보기 */}
            </Link>
          </div>
          <div className="status-wrap">
            <strong className="status">{getTranslatedNameById("TWVudUl0ZW06NTUx")} {/* 주문 취소됨 */}</strong>
            <span className="caption">{window.WSdate(order.node.created)}</span>
          </div>
          {
            order.node.lines.length > 0 &&
            order.node.lines.map((line,line_index)=>(
              <div key={line_index} className="item-info-wrap">
                <div className="img-wrap">
                  {
                    line.thumbnail && line.thumbnail.url &&
                    <Link to={`/product/${line?.variant?.product?.id}`}>
                      <img src={line.thumbnail.url} alt=""></img>
                    </Link>
                  }
                </div>
                <div className="text-wrap">
                  {/* <p className="date">05/23(목)까지 배송 예정</p> */}
                  {
                    line.variant && line.variant.product &&

                    <>
                      
                      <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                      <p className="name">{line.productName} / {line.productSku}</p>
                      <p className="price">
                        {/* 언어에 따른 조건부 텍스트 */}
                        {language === 'KO' ? (
                          <>
                            {line.totalPrice.gross.amount}원 / {line.quantity}개
                          </>
                        ) : (
                          <>
                            {line.totalPrice.gross.amount}{getTranslatedNameById("TWVudUl0ZW06NTUz")} {/* 원 */} / {line.quantity} {getTranslatedNameById("TWVudUl0ZW06Mjk4")} {/* 개 */}
                          </>
                        )}
                      </p>
                    </>
                  }
                </div>
              </div>
            ))
          }
          <div className="btn-wrap flex mt16">
            {/* <button 
              type="button" 
              className="btn purple10"
              onClick={()=>navigate('/mymenu/request-cancel1')}
            >
              장바구니 담기
            </button> */}
          </div>
        </li>
      }
      {/* 취소완료(환불됨) CANCELED [END] */}

      {/* 취소됨 CANCELED [START] */}
      {
        (
          order.node.status === 'CANCELED' &&
          ! order.node.paymentStatus === 'FULLY_REFUNDED'

        ) &&
        <li key={index} className="card order2">
          <div className="order-number-wrap">
            <span className="order-number">
              {getTranslatedNameById("TWVudUl0ZW06NDEy")} {/* 주문번호 */} {order.node.id}                    
            </span>
            <Link to={`/order/order-detail/${order.node.id}`} className="more-btn" title="">
              {getTranslatedNameById("TWVudUl0ZW06NDEz")} {/* 주문 상세보기 */}
            </Link>
          </div>
          <div className="status-wrap">
            <strong className="status">{getTranslatedNameById("TWVudUl0ZW06NTUy")} {/* 취소됨 */}</strong>
            <span className="caption">{window.WSdate(order.node.created)}</span>
          </div>
          {
            order.node.lines.length > 0 &&
            order.node.lines.map((line,line_index)=>(
              <div key={line_index} className="item-info-wrap">
                <div className="img-wrap">
                  {
                    line.thumbnail && line.thumbnail.url &&
                    <Link to={`/product/${line?.variant?.product?.id}`}>
                      <img src={line.thumbnail.url} alt=""></img>
                    </Link>
                  }
                </div>
                <div className="text-wrap">
                  {/* <p className="date">05/23(목)까지 배송 예정</p> */}
                  {
                    line.variant && line.variant.product &&

                    <>
                      
                      <p className="brand">{line.variant.product.vendor.storeNameKo}</p>
                      <p className="name">{line.productName} / {line.productSku}</p>
                      <p className="price">
                        {/* 언어에 따른 조건부 텍스트 */}
                        {language === 'KO' ? (
                          <>
                            {line.totalPrice.gross.amount}원 / {line.quantity}개
                          </>
                        ) : (
                          <>
                            {line.totalPrice.gross.amount}{getTranslatedNameById("TWVudUl0ZW06NTUz")} {/* 원 */} / {line.quantity} {getTranslatedNameById("TWVudUl0ZW06Mjk4")} {/* 개 */}
                          </>
                        )}
                      </p>
                    </>
                  }
                </div>
              </div>
            ))
          }
          <div className="btn-wrap flex mt16">
            <button 
              type="button" 
              className="btn purple10"
              onClick={()=>navigate(`/order/request-cancel/${order.node.id}`)}
            >
              {getTranslatedNameById("TWVudUl0ZW06NTM5")} {/* 취소요청 */}
            </button>
            {/* <button 
              type="button" 
              className="btn purple10"
              onClick={()=>navigate('/mymenu/request-cancel1')}
            >
              장바구니 담기
            </button> */}
          </div>
        </li>
      }
      {/* 취소됨 CANCELED [END] */}


    </>

  );
};

export default OrderStatusCard;
